<template>
  <div class="app-container">
    <div class="navTow original">
      <img src="@/assets/image/webTopPicWhite.png"
           alt=""/>
      <div class="right">
        <div class="navBox">
          <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
                   background-color="#2a50b7" text-color="#fff" active-text-color="#66CEFC">
            <el-menu-item index="goUrl2">首页</el-menu-item>
            <el-menu-item index="/homePage">贸易服务区主页</el-menu-item>
            <el-menu-item index="/exportProducts">出口产品</el-menu-item>
            <el-menu-item index="/importProducts">进口产品</el-menu-item>
            <el-menu-item index="/memberDirectory">会员单位名录</el-menu-item>
            <el-menu-item index="/businessInsights">商机洞察</el-menu-item>
            <el-menu-item index="goUrl">综合业务办理</el-menu-item>
          </el-menu>
        </div>
        <div v-if="isLoggedIn" class="loginBntBox navColor" @click="goPersonCenter">
          <div>用户中心</div>
        </div>
        <div v-if="!isLoggedIn" class="loginBntBox navColor" @click="toLogin">
          <div>登录 / 注册</div>
        </div>
      </div>
    </div>
    <div class="navTow ceiling" v-show="showNav">
      <img src="@/assets/image/webTopPicBlue.png"
           alt=""/>
      <div class="right">
        <div class="navBox">
          <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
                   text-color="#2a50b7" active-text-color="#2a50b7">
            <el-menu-item index="goUrl2">首页</el-menu-item>
            <el-menu-item index="/">贸易服务区主页</el-menu-item>
            <el-menu-item index="/exportProducts">出口产品</el-menu-item>
            <el-menu-item index="/importProducts">进口产品</el-menu-item>
            <el-menu-item index="/memberDirectory">会员单位名录</el-menu-item>
            <el-menu-item index="/businessInsights">商机洞察</el-menu-item>
            <el-menu-item index="goUrl">综合业务办理</el-menu-item>
          </el-menu>
        </div>
        <div v-if="isLoggedIn" class="loginBntBox2 navColor" @click="goPersonCenter">
          <div>用户中心</div>
        </div>
        <div v-if="!isLoggedIn" class="loginBntBox2 navColor" @click="toLogin">
          <div>登录 / 注册</div>
        </div>
      </div>
    </div>
    <div class="center">
      <router-view></router-view>
    </div>
    <div class="footer">
      <div class="footerBox">
        <div class="imgBox">
          <div @click="toFooterUrl('4')">
            <img src="../assets/image/zwlogo04.png" alt=""/>
            <p>中国政府网</p>
          </div>
          <div @click="toFooterUrl('3')">
            <img src="../assets/image/zwlogo03.png" alt=""/>
            <p>山东省人民政府网</p>
          </div>
          <div @click="toFooterUrl('1')">
            <img src="../assets/image/zwlogo01.png" alt=""/>
            <p>青岛政务网</p>
          </div>
          <div @click="toFooterUrl('2')">
            <img src="../assets/image/zwlogo02.png" alt=""/>
            <p>市南区政务网</p>
          </div>
        </div>
        <div class="borderStyleLeft"></div>
        <div class="middle">
          <div>中马互贸创新综合服务平台</div>
          <p>
            联系地址：山东省青岛市市南区宁夏路288号青岛软件园11号楼A座611-29
          </p>
          <p>联系邮箱：service@mycntrade.com</p>
        </div>
        <div class="borderStyleRight"></div>
        <div class="QRcodeBox">
          <div>
            <img src="../assets/image/QRcode1.png" alt=""/>
            <p>爱山东</p>
          </div>
          <div>
            <img src="../assets/image/QRcode2.png" alt=""/>
            <p>青岛政务网</p>
          </div>
          <div>
            <img src="../assets/image/QRcode3.png" alt=""/>
            <p>中马互贸</p>
          </div>
        </div>
      </div>
      <div class="filings">
        <div>
          <img src="../assets/image/download.png" alt=""/>
          <span @click="toFilings('1')">
                  鲁公网安备 37020202001835号 &nbsp;&nbsp;&nbsp;|
                <span @click="toFilings('2')">鲁ICP备2023021508号-2</span>
                </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {debounce} from "lodash";
import store from "@/store";
import config from "@/config"

export default {
  name: "Layout",
  data() {
    return {
      showNav: false,
      breadcrumb: {path: '', name: ''},
      indexType: '1',
      isLogin: false
    }
  },
  computed: {
    ...mapState({
      isLoggedIn: state => state.isLoggedIn,
      username: state => state.username,
      activeIndex: state => state.activeIndex
    }),
  },
  created() {
  },
  mounted() {
    this.handleScroll = debounce(this.checkScrollPosition, 100);
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    checkScrollPosition() {
      if (window.scrollY >= 400) {
        if (!this.showNav) {
          this.showNav = true;
        }
      } else {
        if (this.showNav) {
          this.showNav = false;
        }
      }
    },
    toLogin() {
      this.$router.push({path: '/login'})
    },
    handleSelect(key) {
      if (key !== 'goUrl' && key !== 'goUrl2') {
        this.$store.commit("setActiveIndex", key);
        if (this.$router.currentRoute.path !== key) {
          this.$router.push({path: key}).catch(() => {
          });
        }
      } else if (key === 'goUrl2') {
        window.open("https://test.mycntrade.com/")
      } else if (key === 'goUrl') {
        const token = store.getters.token;
        if (token) {
          window.open(config.redirectUrl + "/api/redirect/redirectToTrade?token=" + token)
        } else {
          window.open("https://platformdev.mycntrade.com");
        }
      }
    },
    toFilings(val) {
      if (val === '2') {
        window.open('https://beian.miit.gov.cn/#/Integrated/index')
      }
      if (val === '1') {
        window.open('https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37020202001835')
      }
    },
    goPersonCenter() {
      if (this.$router.currentRoute.path !== '/personCenter') {
        this.$router.push({path: '/personCenter'}).catch(() => {
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.navTow {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 0 6%;
  z-index: 10;

  .right {
    font-size: 16px;
    font-weight: 400;
    color: #2a50b7;
    display: flex;
    align-items: center;

    .navBox {
      height: 60px;
    }

    .loginBntBox {
      width: 93px;
      height: 27px;
      background: #2a50b7;
      border-radius: 16px;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 27px;
      color: #ffffff;
      cursor: pointer;
    }

    .loginBntBox2 {
      width: 93px;
      height: 27px;
      background: #ffffff;
      border-radius: 16px;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 27px;
      color: #2a50b7;
      cursor: pointer;
    }

    .navColor {
      background: #ffffff;
      color: #2a50b7;
    }
  }
}

.ceiling {
  position: fixed;
  top: 0;
  box-shadow: 0px 1px 0px #dddddd;
  background: #ffffff;
}

.original {
  background: #2a50b7;
}

.heartMouth {
  min-height: calc(100vh - 266px);
}

.center {
  min-height: calc(100vh - 266px);
}

.footer {
  background: #2d354d;
  padding: 50px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerBox {
  display: flex;

  .imgBox {
    display: flex;

    div {
      margin: 0 10px;
      cursor: pointer;

      p {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #ffffff;
      }
    }
  }

  .borderStyleLeft {
    height: 100px;
    border: 1px solid #67718e;
    margin: 0 20px 0 10px
  }

  .borderStyleRight {
    height: 100px;
    border: 1px solid #67718e;
    margin: 0 10px 0 20px
  }

  .middle {
    color: #ffffff;
    line-height: 24px;
    font-weight: 400;

    div {
      font-size: 22px;
    }

    p {
      font-size: 14px;
      margin-top: 5px;
    }
  }

  .QRcodeBox {
    display: flex;
    color: #fff;

    div {
      margin: 0 10px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      margin-top: 10px;
      text-align: center;
    }
  }
}

.filings {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
  margin-top: 10px;

  div {
    display: flex;
    align-items: center;

    img {
      margin-right: 2px;
    }

    span {
      cursor: pointer;
      margin: 0 10px;
    }
  }
}

.el-menu.el-menu--horizontal {
  border-bottom: none;
}
</style>
