<template>
  <div class="app-container">
    <div style="margin:20px;"></div>
    <div style="padding: 0 12%">
      <el-row :gutter="20">
        <el-col :span="20">
          <el-card shadow="never">
            <div slot="header" class="clearfix">
              <breadcrumb style="margin-bottom: 20px;"></breadcrumb>
              <span class="el-card-title">产品详情</span>
              <span class="el-card-describe">Product Details</span>
            </div>
            <div style="display: flex;padding: 20px;">
              <img :src="productInfo.goodsLogo" style="width: 350px;height: 350px;border-radius: 6px;">
              <div style="margin-left: 20px;">
                <div class="productTitle">{{ productInfo.goodsName }}</div>
                <div style="display: flex;">
                  <div class="address">
                    <img src="../assets/image/address.png" style="height: 20px;width: 20px;">
                    <span style="font-weight: 400;font-size: 14px;margin-left: 5px;">{{
                        productInfo.originPlace
                      }}</span>
                  </div>
                  <div class="address" style="margin-left: 20px;">
                    <img src="../assets/image/success.png" style="height: 20px;width: 20px;">
                    <span style="font-weight: 400;font-size: 14px;margin-left: 5px;">货源所属商家已经过真实性核验</span>
                  </div>
                </div>
                <div style="margin-top: 20px;line-height: 28px;" v-html="productInfo.goodsContent">
                </div>
              </div>
            </div>
          </el-card>
          <div style="margin: 20px;"></div>
          <el-card shadow="never">
            <div slot="header" class="clearfix">
              <span class="el-card-title">供应商信息</span>
              <span class="el-card-describe">Supplier Information</span>
            </div>
            <div style="display: flex;align-items: center;justify-content: space-between;">
              <div class="entTitle" @click="lookDetail">{{ productInfo.companyName }}</div>
              <div style="display: flex;align-items: center;" v-if="productInfo.companyCountry === '1'">
                <img src="../assets/image/china.png" style="width: 36px;"/>
                <div class="countryType">中国大陆企业</div>
              </div>
              <div style="display: flex;align-items: center;" v-if="productInfo.companyCountry === '2'">
                <img src="../assets/image/malai.png" style="width: 36px;"/>
                <div class="countryType">马来西亚企业</div>
              </div>
            </div>
            <el-divider></el-divider>
            <div style="display: flex;flex-wrap: wrap;">
              <div class="entFlag" v-if="productInfo.certificationMark.includes('1')">
                <img src="../assets/image/ent1.png" alt="">
                <div>
                  <div class="entType">企业已通过营业执照审核</div>
                  <div class="entType" style="color: #999999">该企业已经上传营业执照，并通过平台系统审核。</div>
                </div>
              </div>
              <div class="entFlag" v-if="productInfo.certificationMark.includes('2')">
                <img src="../assets/image/ent2.png" alt="">
                <div>
                  <div class="entType">海关已验证报关单真实性</div>
                  <div class="entType" style="color: #999999">
                    该企业开通了海关报关单服务并通过海关审核，贸易背景真实可信。
                  </div>
                </div>
              </div>
              <div class="entFlag" v-if="productInfo.certificationMark.includes('3')">
                <img src="../assets/image/ent3.png" alt="">
                <div>
                  <div class="entType">企业信用报告可在线查询</div>
                  <div class="entType" style="color: #999999">该企业的企业信用报告可通过平台公开查询、购买。</div>
                </div>
              </div>
              <div class="entFlag" v-if="productInfo.certificationMark.includes('4')">
                <img src="../assets/image/ent4.png" alt="">
                <div>
                  <div class="entType">企业已完成国际HALAL认证</div>
                  <div class="entType" style="color: #999999">
                    该企业旗下的产品完成了国际清真认证，可与伊斯兰国家进行贸易。
                  </div>
                </div>
              </div>
              <div class="entFlag" v-if="productInfo.certificationMark.includes('5')">
                <img src="../assets/image/ent5.png" alt="">
                <div>
                  <div class="entType">已通过GCA国际信用认证</div>
                  <div class="entType" style="color: #999999">该企业获得GCA国际信用认证，贸易风险可控。</div>
                </div>
              </div>
              <div class="entFlag" v-if="productInfo.certificationMark.includes('6')">
                <img src="../assets/image/ent6.png" alt="">
                <div>
                  <div class="entType">成功通过RMD全球风险排查</div>
                  <div class="entType" style="color: #999999">该企业未出现在RMD全球企业风险库中，排除重大风险。</div>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="4">
          <div style="display: flex;flex-direction: column">
            <productTypes></productTypes>
            <memberService></memberService>
          </div>
        </el-col>
      </el-row>
    </div>
    <div style="margin: 20px;"></div>
  </div>
</template>
<script>
import productTypes from "@/components/public/productTypes.vue";
import memberService from "@/components/public/memberServices.vue";
import FormData from "form-data";
import breadcrumb from "@/components/public/breadcrumb.vue";
import axios from '@/plugins/request';
import config from "@/config";

export default {
  name: "productDetails",
  components: {breadcrumb, memberService, productTypes},
  data() {
    return {
      goodsId: null,
      productInfo: {},
    }
  },
  created(){
    let breadcrumb = [
      {name: '首页', path: '/homePage'},
      {name: '产品详情', path: '/productDetails'},
    ];
    this.$store.commit("setBreadcrumb", breadcrumb);
    this.goodsId = JSON.parse(window.localStorage.getItem("goodsId"));
    let data = new FormData();
    data.append('goodsId', this.goodsId);
    axios.post(config.redirectUrl + "/api/goods/queryGoodsDetail", data).then(res => {
      if (res.data.code === 0) {
        this.productInfo = res.data.data
      }
    })
  },
  methods: {
    lookDetail() {
      window.localStorage.setItem("companyId", JSON.stringify(this.productInfo.companyId));
      window.open(window.location.origin + "/companyDetails")
    },
  }
}
</script>
<style scoped lang="scss">

.entTitle {
  font-weight: bold;
  font-size: 20px;
  color: #306FC7;
  line-height: 55px;
  cursor: pointer;
}

.countryType {
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  line-height: 26px;
  margin-left: 10px;
}

.entType {
  font-weight: 600;
  font-size: 14px;
  color: #306FC7;
  line-height: 26px;
  margin-left: 20px;
}

.entFlag {
  display: flex;
  align-items: center;
  flex: 0 0 50%;
  margin-bottom: 20px;
}

.productTitle {
  font-weight: 600;
  font-size: 24px;
  color: #306FC7;
  line-height: 40px;
}

.address {
  font-size: 12px;
  font-weight: 200;
  display: flex;
  align-items: center;
  justify-content: center;
}

.entDesc {
  font-weight: 200;
  font-size: 14px;
  color: #666666;
  line-height: 26px;
  margin-top: 10px;
}
</style>
