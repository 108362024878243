<template>
  <div>
    <el-card style="height: 340px;margin-top: 20px;" shadow="never">
      <div slot="header" class="clearfix">
        <span class="el-card-title">会员服务</span>
      </div>
      <div>
        <div style="text-align: center;">
          <img src="../../assets/image/code.png" style="width: 76px;">
        </div>
        <div v-for="(item,index) in member" :key="index">
          <div class="member">
            <img :src="item.img">
            <div style="margin-left: 5px;" class="title">{{ item.title }}</div>
          </div>
          <div class="content">{{ item.content }}</div>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  name: "memberService",
  data() {
    return {
      member: [
        {img: require("../../assets/image/email.png"), title: '联系邮箱', content: 'service@mycntrade.com'},
        // {
        //   img: require("../../assets/image/position.png"),
        //   title: '详细地址',
        //   content: '山东省青岛市市南区宁夏路288号青岛软件园11号楼A座611-29'
        // },
        // {img: require("../../assets/image/phone.png"), title: '联系电话', content: '13581500803 杨经理'},
      ],
    }
  }
}
</script>

<style scoped lang="scss">
.member {
  display: flex;
  align-items: center;
  margin: 8px 0;

  .title {
    font-size: 14px;
    color: #666666;
  }
}

.content {
  font-size: 12px;
  color: #999999;
}
</style>
