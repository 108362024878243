<template>
  <div>
    <el-card style="height: 340px;" shadow="never">
      <div slot="header" class="clearfix">
        <span class="el-card-title">商品分类</span>
      </div>
      <div>
        <div v-for="(item,index) in productTypes" :key="index" class="productTypes">
          <img :src="item.icon" style="width: 20px;height: 20px;">
          <div @click="handleproductTypes(item.id,item.name)"
               style="cursor: pointer;margin: 0 0 0 5px;font-size: 14px;line-height: 36px;">
            {{ item.name }}
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import config from "@/config";

export default {
  name: "productTypes.vue",
  data() {
    return {
      productTypes: [],
    }
  },
  created() {
    this.getProductTypes();
  },
  methods: {
    getProductTypes() {
      this.$axios.post(config.redirectUrl + "/api/goods/queryGoodsCateTree").then(res => {
        if (res.data.code === 0) {
          this.productTypes = res.data.data
        }
      })
    },
    handleproductTypes(productTypesId, productTypesName) {
      window.localStorage.setItem("productTypesId", productTypesId)
      window.localStorage.setItem("productTypesName", productTypesName)
      if (this.$router.currentRoute.path !== '/productsType') {
        this.$router.push({path: '/productsType'}).catch(() => {
        });
      } else {
        let data = {
          productTypesId: productTypesId,
          productTypesName: productTypesName
        }
        this.$emit("valueFormType", JSON.stringify(data))
      }
    }
  }
}
</script>

<style scoped lang="scss">
.productTypes {
  display: flex;
  align-items: center;
}
</style>
<!--<template>-->
<!--  <div class="menu-container">-->
<!--    <el-card style="height: 340px;width: 100%" shadow="never">-->
<!--      <div slot="header" class="clearfix">-->
<!--        <span>商品分类</span>-->
<!--      </div>-->
<!--      <div style="overflow-y: auto">-->
<!--        <div class="menu-item"-->
<!--             v-for="(item, index) in menuItems"-->
<!--             :key="index"-->
<!--             @mouseover="handleMouseOver(index)"-->
<!--             @mouseleave="handleMouseLeave"-->
<!--             @click="handleproductTypes(item.name)">-->
<!--          {{ item.name }}-->
<!--        </div>-->
<!--      </div>-->
<!--    </el-card>-->
<!--    <div class="menu-right" v-if="hoverItem !== null"-->
<!--         @mouseover="handleMouseOverRight"-->
<!--         @mouseleave="handleMouseLeaveRight">-->
<!--      <div v-for="(subItem, subIndex) in menuItems[hoverItem].children" :key="subIndex" class="sub-item"-->
<!--           @click="handleproductTypes(subItem.name)">-->
<!--        {{ subItem.name }}-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--export default {-->
<!--  data() {-->
<!--    return {-->
<!--      hoverItem: null,-->
<!--      menuItems: []-->
<!--    };-->
<!--  },-->
<!--  created() {-->
<!--    this.$axios.post("http://srm.mycntrade.com/prod/api/goods/queryGoodsCateTree").then(res => {-->
<!--      // 创建一个字典方便快速查找每个节点-->
<!--      const idMapping = res.data.data.reduce((acc, el) => {-->
<!--        acc[el.id] = el;-->
<!--        return acc;-->
<!--      }, {});-->

<!--      // 创建父子结构-->
<!--      const root = [];-->
<!--      res.data.data.forEach(el => {-->
<!--        // 根节点或没有找到父节点的节点-->
<!--        if (el.pId === 0 || !idMapping[el.pId]) {-->
<!--          root.push(el);-->
<!--        } else {-->
<!--          // 根据字典找到父节点-->
<!--          const parentEl = idMapping[el.pId];-->
<!--          if (parentEl) {-->
<!--            parentEl.children = [...(parentEl.children || []), el];-->
<!--          }-->
<!--        }-->
<!--      });-->
<!--      this.menuItems = root;-->
<!--    })-->
<!--  },-->
<!--  methods: {-->
<!--    handleproductTypes(productTypes) {-->
<!--      this.$emit('valueFromChild', productTypes);-->
<!--    },-->
<!--    handleMouseOver(index) {-->
<!--      clearTimeout(this.timer);-->
<!--      this.hoverItem = index;-->
<!--    },-->
<!--    handleMouseLeave() {-->
<!--      this.timer = setTimeout(() => {-->
<!--        this.hoverItem = null;-->
<!--      }, 500);-->
<!--    },-->
<!--    handleMouseOverRight() {-->
<!--      clearTimeout(this.timer);-->
<!--    },-->
<!--    handleMouseLeaveRight() {-->
<!--      this.timer = setTimeout(() => {-->
<!--        this.hoverItem = null;-->
<!--      }, 500);-->
<!--    }-->
<!--  }-->
<!--};-->
<!--</script>-->

<!--<style scoped>-->

<!--.menu-container {-->
<!--  display: flex;-->
<!--  position: relative;-->
<!--}-->

<!--.menu-item {-->
<!--  padding: 8px 20px;-->
<!--  cursor: pointer;-->
<!--  display: flex;-->
<!--  align-items: center;-->
<!--  font-size: 16px;-->
<!--}-->

<!--.menu-item:hover {-->
<!--  background-color: #c9302c;-->
<!--}-->

<!--.menu-right {-->
<!--  position: absolute;-->
<!--  left: 100%;-->
<!--  top: 0;-->
<!--  background-color: #fff;-->
<!--  color: #333;-->
<!--  padding: 10px;-->
<!--  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);-->
<!--  min-width: 150px;-->
<!--}-->

<!--.sub-item {-->
<!--  padding: 5px 10px;-->
<!--  font-size: 14px;-->
<!--  cursor: pointer;-->
<!--}-->

<!--.sub-item:hover {-->
<!--  background: #666666;-->
<!--}-->
<!--</style>-->

