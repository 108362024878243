<template>
  <div class="right_bottom">
    <myScroll :config="config" style="width:100%;height:280px"/>
  </div>
</template>

<script>
import myScroll from "@/components/component/nowScroll.vue";
import exportTop5 from "@/public/exportTop5.json"

export default {
  data() {
    return {
      config: {
        waitTime: 30000,
        unit: "%",
        carousel: 'page',
        sort: false,
        data: []
      },
    };
  },
  components: {
    myScroll
  },
  created() {
    this.config.data = exportTop5;
  }
};
</script>
<style lang='scss' scoped>
.right_bottom {
  box-sizing: border-box;
}
</style>
