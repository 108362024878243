<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
          v-for="(item, index) in breadcrumb"
          :key="index"
          @click.native.prevent="handleClick(item.path)"
      >
        {{ item.name }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "breadcrumb",
  data() {
    return {}
  },
  computed: {
    ...mapState({
      breadcrumb: state => state.breadcrumb,
    }),
  },
  methods: {
    handleClick(path) {
      if (this.$router.currentRoute.path !== path) {
        this.$store.commit("setActiveIndex", path);
        this.$router.push({path: path}).catch(() => {
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-breadcrumb__inner {
  cursor: pointer !important;
}
</style>
