<template>
  <div class="app-container">
    <div style="padding: 0 12%">
      <el-card shadow="never">
        <div slot="header" class="clearfix">
          <span class="el-card-title">登录或注册</span>
          <span class="el-card-describe">Sign in or Sign up</span>
        </div>
        <div>
          <el-row :gutter="20">
            <el-col :span="12">
              <img src="../assets/image/login.png" style="width: 100%">
            </el-col>
            <el-col :span="12">
              <el-form ref="registForm" label-position="top" :model="registForm" :rules="registRules"
                       class="regist-form" style="margin: 0 auto;">
                <div class="regist-title">
                  <div class="regist-text" style="cursor: pointer" @click="handleLogin">现在登录</div>
                  <div class="regist-text" style="margin: 20px;"> |</div>
                  <div class="regist-text" style="color: #306FC7;cursor: pointer">新用户注册</div>
                </div>
                <h4>登录账号：</h4>
                <el-form-item prop="registName">
                  <el-input
                      v-model="registForm.registName"
                      type="text"
                      auto-complete="off"
                      placeholder="手机号或邮箱"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item prop="validateCode">
                  <el-input
                      v-model="registForm.validateCode"
                      auto-complete="off"
                      placeholder="输入右图文字"
                      style="width: 63%"
                  >
                  </el-input>
                  <div class="regist-code">
                    <img :src="codeUrl" @click="getImage" class="regist-code-img"/>
                  </div>
                </el-form-item>
                <el-form-item prop="messageCode">
                  <el-input
                      v-model="registForm.messageCode"
                      auto-complete="off"
                      placeholder="确认验证码"
                      style="width: 63%"
                  >
                  </el-input>
                  <div class="regist-code">
                    <div class="regist-code-img regist-code-my" @click="getCode"
                         :class="countdown > 0 ? 'disabled': ''">
                      {{ countdown > 0 ? `${countdown}s 后重新发送` : '发送验证码' }}
                    </div>
                  </div>
                </el-form-item>
                <h4>设置登录密码：</h4>
                <el-form-item prop="password">
                  <el-input
                      v-model="registForm.password"
                      type="password"
                      auto-complete="off"
                      placeholder="密码"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item prop="checkPassword">
                  <el-input
                      v-model="registForm.checkPassword"
                      type="password"
                      auto-complete="off"
                      placeholder="确认密码"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item style="width:100%;">
                  <el-button
                      round
                      :loading="loading"
                      size="medium"
                      type="success"
                      style="width:100%;"
                      @click.native.prevent="handleRegist"
                  >
                    <span v-if="!loading">注 册</span>
                    <span v-else>注 册 中...</span>
                  </el-button>
                  <div style="float: left;">
                    <el-checkbox v-model="registForm.read" style="margin:0px 0px 25px 0px;">
                      <label style="color: #000">我已读并同意 <span
                          style="color: #2A50B7;cursor: pointer"
                          onclick="window.open('https://mycntrade.oss-cn-qingdao.aliyuncs.com/file/XY%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.pdf')">服务协议</span>
                        和 <span style="color: #2A50B7;cursor: pointer"
                                 onclick="window.open('https://mycntrade.oss-cn-qingdao.aliyuncs.com/file/XY%E9%9A%90%E7%A7%81%E5%8D%8F%E8%AE%AE.pdf')">隐私协议</span></label>
                    </el-checkbox>
                  </div>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </div>
    <div class="el-register-footer">
      <span>© mycnTrade.com 中国-马来西亚互贸创新综合服务平台 版权所有</span>
    </div>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%">
      <span>点击确定表示您已阅读并同意 <span
          style="color: #2A50B7;cursor: pointer"
          onclick="window.open('https://mycntrade.oss-cn-qingdao.aliyuncs.com/file/XY%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.pdf')">服务协议</span>
                    和 <span style="color: #2A50B7;cursor: pointer"
                             onclick="window.open('https://mycntrade.oss-cn-qingdao.aliyuncs.com/file/XY%E9%9A%90%E7%A7%81%E5%8D%8F%E8%AE%AE.pdf')">隐私协议</span></span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="confirmRead">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import FormData from "form-data";
import config from "@/config"

export default {
  name: "register",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.registForm.checkPassword !== '') {
          this.$refs.registForm.validateField('checkPassword');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.registForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      codeUrl: "",
      registForm: {
        registName: "",
        password: "",
        checkPassword: "",
        validateCode: "",
        messageCode: "",
        read: false,
      },
      registRules: {
        registName: [
          {required: true, trigger: "blur", message: "请输入手机号码或邮箱"}
        ],
        validateCode: [
          {required: true, trigger: "blur", message: "请输入右图的文字"}
        ],
        messageCode: [
          {required: true, trigger: "blur", message: "请输入您的验证码"}
        ],
        password: [
          {validator: validatePass, trigger: "blur"}
        ],
        checkPassword: [
          {validator: validatePass2, trigger: "blur"}
        ],
        code: [{required: true, trigger: "change", message: "请输入验证码"}]
      },
      loading: false,
      uuid: '',
      dialogVisible: false,
      countdown: 0,
      timer: null
    };
  },
  created() {
    this.getImage();
  },
  methods: {
    getImage() {
      this.$axios.get(config.redirectUrl + "/api/captcha/captchaImage").then(res => {
        this.codeUrl = res.data.imgBase64;
        this.uuid = res.data.uuid;
      })
    },
    startCountdown() {
      this.countdown = 60; // 设置倒计时时间
      // 清除已有的计时器
      if (this.timer) {
        clearInterval(this.timer);
      }
      // 每秒减少倒计时
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.timer); // 清除倒计时
        }
      }, 1000);
    },
    getCode() {
      if (this.countdown > 0) return;
      if (this.registForm.registName === '') {
        this.$message.error("请检查手机号码或邮箱")
      } else if (this.registForm.validateCode === '') {
        this.$message.error("请填写右图验证码")
      } else {
        const emailPattern = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
        const phonePattern = /^1[3-9]\d{9}$/;
        if (emailPattern.test(this.registForm.registName)) {
          let data = new FormData();
          data.append('email', this.registForm.registName);
          data.append('validateCode', this.registForm.validateCode);
          data.append('uuid', this.uuid);
          this.$axios.post(config.redirectUrl + "/api/user/sendEmail", data).then(res => {
            if (res.data.code === 500) {
              this.$message.error(res.data.msg)
            } else {
              this.$message.success(res.data.msg)
              this.startCountdown();
            }
          })
        } else if (phonePattern.test(this.registForm.registName)) {
          let data = new FormData();
          data.append('phone', this.registForm.registName);
          data.append('validateCode', this.registForm.validateCode);
          data.append('uuid', this.uuid);
          this.$axios.post(config.redirectUrl + "/api/user/sendSms", data).then(res => {
            if (res.data.code === 500) {
              this.$message.error(res.data.msg)
            } else {
              this.$message.success(res.data.msg)
              this.startCountdown();
            }
          })
        }
      }
    },
    handleRegist() {
      if (this.registForm.read) {
        let data = {
          loginName: this.registForm.registName,
          password: this.registForm.password,
          validateCode: this.registForm.validateCode,
          messageCode: this.registForm.messageCode
        }
        let configs = {
          headers: {
            'Content-Type': 'application/json'
          },
        };
        this.$axios.post(config.redirectUrl + "/api/user/platformRegister", JSON.stringify(data), configs).then(res => {
          if (res.data.code === 500) {
            this.$message.error(res.data.msg);
          } else {
            this.$message.success(res.data.msg);
            this.$router.push({path: "/login"});
          }
        })
      } else {
        this.dialogVisible = true;
      }
    },
    confirmRead() {
      this.registForm.read = true;
      this.dialogVisible = false;
    },
    handleLogin() {
      this.$router.push({path: '/login'})
    },
  }
}
</script>


<style scoped lang="scss">
.app-container {
  padding: 40px;
}

.title {
  font-weight: 600;
  font-size: 20px;
  color: #666666;
}

.subText {
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  margin-left: 10px;
}

.title {
  margin: 0px auto 30px auto;
  text-align: center;
  color: #707070;
}

.regist-form {
  border-radius: 6px;
  background: #ffffff;
  width: 400px;
  padding: 25px 25px 5px 25px;

  .el-input {
    height: 38px;

    input {
      height: 38px;
    }
  }

  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}

.regist-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}

.regist-code {
  width: 33%;
  height: 38px;
  float: right;

  img {
    cursor: pointer;
    vertical-align: middle;
  }
}

.regist-code-img {
  height: 38px;
}

.regist-title {
  display: flex;
  align-items: center;
}

.regist-text {
  font-weight: 400;
  font-size: 28px;
  color: #999999;
  line-height: 46px;
}

.el-register-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #666666;
  font-family: Arial;
  font-size: 14px;
  letter-spacing: 1px;
}

.regist-code-my {
  border-radius: 6px;
  border: solid 1px #999999;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

.disabled {
  background-color: #efefef;
  cursor: not-allowed;
  border: solid 1px #cccccc;
  color: #999999;
}
</style>
