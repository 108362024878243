<template>
  <div class="app-container">
    <div style="padding: 0 12%">
      <el-card shadow="never">
        <div slot="header" class="clearfix">
          <span class="el-card-title">登录或注册</span>
          <span class="el-card-describe">Sign in or Sign up</span>
        </div>
        <div>
          <el-row :gutter="20">
            <el-col :span="12">
              <img src="../assets/image/login.png" style="width: 100%">
            </el-col>
            <el-col :span="12">
              <el-form ref="loginForm" label-position="top" :model="loginForm" :rules="loginRules" class="login-form"
                       style="margin: 0 auto;">
                <div class="login-title">
                  <div class="login-text" style="color: #306FC7;cursor: pointer">现在登录</div>
                  <div class="login-text" style="margin: 20px;"> |</div>
                  <div class="login-text" style="cursor: pointer" @click="handleRegister">新用户注册</div>
                </div>
                <h4>登录账号：</h4>
                <el-form-item prop="username">
                  <el-input
                      v-model="loginForm.username"
                      type="text"
                      auto-complete="off"
                      placeholder="账号"
                  >
                  </el-input>
                </el-form-item>
                <h4>登录密码：</h4>
                <el-form-item prop="password">
                  <el-input
                      v-model="loginForm.password"
                      type="password"
                      auto-complete="off"
                      placeholder="密码"
                      @keyup.enter.native="handleLogin"
                  >
                  </el-input>
                </el-form-item>
                <!--                          <div id="captcha-element"></div>-->
                <el-checkbox v-model="loginForm.rememberMe" style="margin:0px 0px 25px 0px;">记住我</el-checkbox>
                <el-form-item style="width:100%;">
                  <el-button
                      round
                      :loading="loading"
                      size="medium"
                      type="success"
                      style="width:100%;"
                      @click.native.prevent="handleLogin"
                  >
                    <span v-if="!loading">登 录</span>
                    <span v-else>登 录 中...</span>
                  </el-button>
                  <div style="float: left;">
                    <el-checkbox v-model="loginForm.read" style="margin:0px 0px 25px 0px;">
                      <label style="color: #000">我已读并同意 <span
                          style="color: #2A50B7;cursor: pointer"
                          onclick="window.open('https://mycntrade.oss-cn-qingdao.aliyuncs.com/file/XY%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.pdf')">服务协议</span>
                        和 <span style="color: #2A50B7;cursor: pointer"
                                 onclick="window.open('https://mycntrade.oss-cn-qingdao.aliyuncs.com/file/XY%E9%9A%90%E7%A7%81%E5%8D%8F%E8%AE%AE.pdf')">隐私协议</span></label>
                    </el-checkbox>
                  </div>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </div>
    <div class="el-login-footer">
      <span>© mycnTrade.com 中国-马来西亚互贸创新综合服务平台 版权所有</span>
    </div>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%">
      <span>点击确定表示您已阅读并同意 <span
          style="color: #2A50B7;cursor: pointer"
          onclick="window.open('https://mycntrade.oss-cn-qingdao.aliyuncs.com/file/XY%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.pdf')">服务协议</span>
                    和 <span style="color: #2A50B7;cursor: pointer"
                             onclick="window.open('https://mycntrade.oss-cn-qingdao.aliyuncs.com/file/XY%E9%9A%90%E7%A7%81%E5%8D%8F%E8%AE%AE.pdf')">隐私协议</span></span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="confirmRead">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import FormData from "form-data";
import config from "@/config";

export default {
  name: "login",
  data() {
    return {
      captcha: null,
      codeUrl: "",
      loginForm: {
        username: "",
        password: "",
        rememberMe: false,
        read: false,
        code: "",
        uuid: "",
      },
      loginRules: {
        username: [
          {required: true, trigger: "blur", message: "请输入您的账号"}
        ],
        password: [
          {required: true, trigger: "blur", message: "请输入您的密码"}
        ],
      },
      loading: false,
      dialogVisible: false,
    };
  },
  created() {
  },
  mounted() {
    // window.initAliyunCaptcha({
    //   SceneId: '1b37xt2h', // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
    //   prefix: '1kre14', // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
    //   mode: 'embed', // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
    //   element: '#captcha-element', // 页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
    //   button: '#captcha-button', // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
    //   captchaVerifyCallback: this.dataFormSubmit, // 业务请求(带验证码校验)回调函数，无需修改
    //   getInstance: this.getInstance, // 绑定验证码实例函数，无需修改
    //   slideStyle: {
    //     width: 368,
    //     height: 40
    //   }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
    //   language: 'cn' // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
    // });
  },
  methods: {
    handleLogin() {
      if (this.loginForm.read) {
        this.$refs.loginForm.validate((valid) => {
          if (valid) {
            let data = new FormData();
            data.append('username', this.loginForm.username);
            data.append('password', this.loginForm.password);
            this.$axios.post(config.redirectUrl + "/api/user/login", data).then(res => {
              if (res.data.code === 0) {
                this.$store.commit("setUsername", this.loginForm.username);
                this.$store.commit("setToken", res.data.data);
                this.$router.push({path: '/personCenter'});
              } else {
                this.$message.error(res.data.msg)
              }
            })
          }
        })
      } else {
        this.dialogVisible = true;
      }
    },
    dataFormSubmit(captchaVerifyParam) {
      this.handleLogin(captchaVerifyParam);
    },
    getInstance(instance) {
      this.captcha = instance;
    },
    handleRegister() {
      this.$router.push({path: '/register'})
    },
    confirmRead() {
      this.loginForm.read = true;
      this.dialogVisible = false;
    }
  }
}
</script>


<style scoped lang="scss">
.app-container {
  padding: 40px;
}

.title {
  margin: 0px auto 30px auto;
  text-align: center;
  color: #707070;
}

.login-form {
  border-radius: 6px;
  background: #ffffff;
  width: 400px;
  padding: 25px 25px 5px 25px;

  .el-input {
    height: 38px;

    input {
      height: 38px;
    }
  }

  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}

.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}

.login-code {
  width: 33%;
  height: 38px;
  float: right;

  img {
    cursor: pointer;
    vertical-align: middle;
  }
}

.login-code-img {
  height: 38px;
}

.login-title {
  display: flex;
  align-items: center;
}

.login-text {
  font-weight: 400;
  font-size: 28px;
  color: #999999;
  line-height: 46px;
}

.el-login-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #666666;
  font-family: Arial;
  font-size: 14px;
  letter-spacing: 1px;
}
</style>
