<template>
  <div class="app-container">
    <div class="bannerStyle">
      <img src="../assets/image/banner/banner04.png" class="bannerItem"/>
      <div class="bannerItem-text">
        <div class="bannerItem-text-title">会员单位名录</div>
        <div class="bannerItem-text-content">中国-马来西亚互贸创新综合服务平台优秀企业会员名录。</div>
      </div>
    </div>
    <div style="margin:20px;"></div>
    <div style="padding: 0 12%;">
      <el-card shadow="never">
        <div slot="header" class="clearfix">
          <breadcrumb style="margin-bottom: 20px;"></breadcrumb>
          <span class="el-card-title">会员单位名录</span>
          <span class="el-card-describe">List of Members</span>
        </div>
        <div>
          <div style="display: flex;">
            <div class="types">
              <img src="../assets/image/china.png" style="width: 36px;">
              <div class="typeTitle" :style="active === '1'? 'color: #666666':'color: #999999'"
                   @click="changeType('1')">中国企业（{{ chinaCount }}家）
              </div>
            </div>
            <div class="types" style="margin-left: 20px;">
              <img src="../assets/image/malai.png" style="width: 36px;">
              <div class="typeTitle" :style="active === '2'? 'color: #666666':'color: #999999'"
                   @click="changeType('2')">马来西亚企业（{{ malaiCount }}家）
              </div>
            </div>
          </div>
          <div class="type"></div>
          <div v-if="active === '1'">
            <div v-for="(item,index) in chinaCompanyList" :key="index" class="member-item">
              <img src="../assets/image/member.png" class="corner-image" alt="Corner">
              <div>
                <div class="name">{{ item.companyName }}</div>
                <div class="subTitle">注册号：<span class="desc">{{ item.businessLicenseCode }}</span></div>
                <div class="subTitle">地址：<span class="desc">{{ item.companyAddress }}</span></div>
              </div>
              <div class="loginBntBox" @click="lookDetail(item)">
                查看详情
              </div>
            </div>
          </div>
          <div v-if="active === '2'">
            <div v-for="(item,index) in malaiCompanyList" :key="index" class="member-item">
              <img src="../assets/image/member.png" class="corner-image" alt="Corner">
              <div>
                <div class="name">{{ item.companyName }}</div>
                <div class="subTitle">注册号：<span class="desc">{{ item.businessLicenseCode }}</span></div>
                <div class="subTitle">地址：<span class="desc">{{ item.companyAddress }}</span></div>
              </div>
              <div class="loginBntBox" @click="lookDetail(item)">
                查看详情
              </div>
            </div>
          </div>
        </div>
        <pagination
            v-show="total>0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="getList"
        />
      </el-card>
      <div style="margin: 20px;"></div>
    </div>
  </div>
</template>
<script>
import breadcrumb from "@/components/public/breadcrumb.vue";
import FormData from "form-data";
import Pagination from "@/components/public/pagination.vue";
import axios from '@/plugins/request';
import config from "@/config";

export default {
  name: "menberDirectory",
  components: {Pagination, breadcrumb},
  data() {
    return {
      active: '1',
      queryParams: {
        pageNum: 1,
        pageSize: 50
      },
      total: 0,
      chinaCount: 0,
      malaiCount: 0,
      chinaCompanyList: [],
      malaiCompanyList: [],
      companyList: [],
    }
  },
  created() {
    let breadcrumb = [
      {name: '首页', path: '/homePage'},
      {name: '会员单位名录', path: '/memberDirectory'},
    ];
    this.$store.commit("setBreadcrumb", breadcrumb);
    this.getList();
    this.getCount();
  },
  methods: {
    getList() {
      let data = new FormData();
      data.append('companyCountry', this.active);
      data.append('pageNum', this.queryParams.pageNum);
      data.append('pageSize', this.queryParams.pageSize);
      axios.post(config.redirectUrl + '/api/company/membership/queryAllCompanies', data).then(res => {
        if (res.data.data.code === 0) {
          this.total = res.data.data.total;
          if (this.active === '1') {
            this.chinaCompanyList = res.data.data.rows;
            this.chinaCompanyList.forEach(e => {
              e.companyAddress = e.companyAddress.length >= 20 ? e.companyAddress.substring(0, 20) + '...' : e.companyAddress;
            })
          } else if (this.active === '2') {
            this.malaiCompanyList = res.data.data.rows;
            this.malaiCompanyList.forEach(e => {
              e.companyAddress = e.companyAddress.length >= 50 ? e.companyAddress.substring(0, 50) + '...' : e.companyAddress;
            })
          }
        }
      })
    },
    getCount() {
      this.$axios.post(config.redirectUrl + '/api/company/membership/queryAllCompaniesGroup').then(res => {
        if (res.data.code === 0) {
          this.chinaCount = res.data.data[1];
          this.malaiCount = res.data.data[2];
        }
      })
    },
    changeType(value) {
      this.active = value;
      this.getList();
    },
    lookDetail(companyInfo) {
      window.localStorage.setItem("companyId", JSON.stringify(companyInfo.id));
      window.open(window.location.origin + "/companyDetails")
    }
  }
}
</script>

<style scoped lang="scss">
.bannerStyle {
  background: #2a50b7;
}

.bannerItem {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  height: 300px;
}

.type {
  display: flex;
  align-items: center
}

.types {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.typeTitle {
  font-weight: 400;
  font-size: 24px;
  color: #666666;
  line-height: 55px;
  margin-left: 10px;
}

.loginBntBox {
  width: 95px;
  height: 45px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #306FC7;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 45px;
  color: #306FC7;
  cursor: pointer;
}

.type + div {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px; /* 新增的对应调整，为了使边缘对齐 */
}

.member-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 calc(50% - 40px);
  border: solid 1px #DDDDDD;
  border-radius: 6px;
  padding: 40px;
  margin: 20px;
}

.name {
  font-size: 20px;
  color: #306FC7;
  line-height: 35px;
}

.subTitle {
  color: #666666;
  font-size: 13px;
  line-height: 20px;
}

.desc {
  font-size: 13px;
  color: #666666;
  line-height: 20px;
}

.corner-image {
  position: absolute;
  top: 5px;
  right: 5px;
}

::v-deep .pagination-container .el-pagination {
  right: 13% !important;
  position: absolute;
}
</style>
