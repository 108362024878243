<template>
  <div class="app-container">
    <div style="margin:20px;"></div>
    <div style="padding: 0 12%">
      <el-card shadow="never">
        <div slot="header" class="clearfix">
          <span class="title">用户中心</span>
          <span class="subText">User Center</span>
        </div>
        <div>
          <el-row>
            <el-col :span="6">
              <el-menu :default-active="active" class="el-menu-vertical-demo" @select="handleSelect">
                <el-menu-item v-for="(nav, index) in navs" :key="index" :index="nav.index">
                  <i :class="nav.icon"></i>
                  <span slot="title">{{ nav.title }}</span>
                </el-menu-item>
              </el-menu>
            </el-col>
            <el-col :span="18">
              <div v-show="active === '1'" style="padding: 0 40px;">
                <div v-if="!isWrite && !isApproval" style="display: flex;flex-direction: column;align-items: center">
                  <img src="../assets/image/medal2.png">
                  <div>您还没有成为会员单位，请尽快填写申请表。</div>
                  <el-button type="primary" style="margin-top: 20px;" @click="writeInfo">立即申请</el-button>
                </div>
                <div v-if="!isWrite && isApproval" style="display: flex;flex-direction: column;align-items: center">
                  <img src="../assets/image/medal2.png">
                  <div>您的申请已提交，请耐心等待平台审核结果。</div>
                  <div>请保持手机通畅，留意接听客户经理电话。</div>
                </div>
                <div v-if="isWrite && isApproval"
                     style="display: flex;border: solid 1px #DBDBDB;border-radius: 6px;padding: 20px;">
                  <img src="../assets/image/medal.png" style="width: 140px;height: 140px;">
                  <div style="margin-left: 30px;">
                    <h3>认证会员企业</h3>
                    <div class="myAppHandle">企业全称：{{ infoForm.companyName }}</div>
                    <div class="myAppHandle">统一社会信用代码：{{ infoForm.businessLicenseCode }}</div>
                    <div class="myAppHandle">企业所在地区：{{ infoForm.location }}</div>
                    <div class="myAppHandle">企业联系人：{{ infoForm.contactPerson }}</div>
                    <div class="myAppHandle">联系电话：{{ infoForm.contactPhone }}</div>
                    <div class="myAppHandle">联系邮箱：{{ infoForm.contactEmail }}</div>
                    <div class="myAppHandle">官方网站：{{
                        infoForm.contactWebsite ? infoForm.contactWebsite : '-'
                      }}
                    </div>
                  </div>
                </div>
                <div v-if="!isWrite || isApproval">
                  <div style="margin: 80px 20px 40px;font-size: 22px;color: #666666;font-weight: 400;">
                    成为平台会员单位后，您将享受到如下服务：
                  </div>
                  <div style="display: flex;justify-content: space-between;">
                    <div v-for="(item,index) in services" :key="index"
                         style="display: flex;flex-direction: column;border: solid 1px #DBDBDB;border-radius: 6px;padding: 20px;flex: 0 0 23%">
                      <img :src="item.img" style="width: 60px;">
                      <div style="margin-top: 20px;font-size: 22px;margin-bottom: 10px;" :style="item.style">
                        {{ item.title }}
                      </div>
                      <div class="myli" v-for="(e,index) in item.server" :key="index">
                        {{ e }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="isWrite && !isApproval">
                  <div style="display: flex;align-items: center;background: #F6F6F6">
                    <img style="width: 80px;" src="../assets/image/medal.png">
                    <div style="margin-left: 10px;color: #666666">
                      填写申请表，通过系统审核后即可成为会员单位。
                    </div>
                  </div>
                  <div style="padding: 20px 8%">
                    <h4 style="color: #666666">企业基本信息</h4>
                    <el-divider></el-divider>
                    <el-form :model="infoForm" :rules="infoRules" ref="infoForm" label-width="180px">
                      <el-form-item label="企业名称：" prop="companyName">
                        <el-input v-model="infoForm.companyName" placeholder="请输入完整、准确的企业全称。"></el-input>
                      </el-form-item>
                      <el-form-item label="统一社会信用代码：" prop="businessLicenseCode">
                        <el-input v-model="infoForm.businessLicenseCode"
                                  placeholder="请输入统一社会信用代码。"></el-input>
                      </el-form-item>
                      <el-form-item label="企业地址：" prop="location">
                        <el-input v-model="infoForm.location" placeholder="请输入完整、准确的企业地址。"></el-input>
                      </el-form-item>
                      <el-form-item label="业务联系人：" prop="contactPerson">
                        <el-input v-model="infoForm.contactPerson" placeholder="请输入"></el-input>
                      </el-form-item>
                      <el-form-item label="联系电话：" prop="contactPhone">
                        <el-input v-model="infoForm.contactPhone" placeholder="请输入"></el-input>
                      </el-form-item>
                      <el-form-item label="联系邮箱：" prop="contactEmail">
                        <el-input v-model="infoForm.contactEmail" placeholder="请输入"></el-input>
                      </el-form-item>
                      <el-form-item label="官网网站：" prop="contactWebsite">
                        <el-input v-model="infoForm.contactWebsite" placeholder="请输入"></el-input>
                      </el-form-item>
                      <h4 style="color: #666666">请输入三个主营跨境商品或服务</h4>
                      <el-divider></el-divider>
                      <el-form-item label="商品或服务名称：" prop="applicationContent">
                        <el-input type="textarea" :rows="3" v-model="infoForm.applicationContent"
                                  placeholder="请输入"></el-input>
                      </el-form-item>
                    </el-form>
                    <div style="display: flex;justify-content: center">
                      <el-button @click="isWrite=false">取消</el-button>
                      <el-button type="primary" @click="submitHandle()">提交</el-button>
                    </div>
                  </div>
                </div>
              </div>
              <!--              综合业务办理-->
              <div v-show="active === '2'">
                <div style="display: flex;flex-wrap: wrap;">
                  <div v-for="(item,index) in business" :key="index" class="business">
                    <div style="display: flex;align-items: center">
                      <img :src="item.img">
                      <div style="margin-left: 10px;">
                        <div style="font-weight: bold;color: #666666;line-height: 30px;">{{ item.title }}</div>
                        <div style="font-size: 12px;color: #666666;font-weight: bold">{{ item.description }}</div>
                      </div>
                    </div>
                    <el-button type="primary" @click="handleClick">申请</el-button>
                  </div>
                </div>
              </div>
              <!--              联系平台客服-->
              <div v-show="active === '3'">
                <div style="display: flex">
                  <div style="display: flex;align-items: center;">
                    <img src="../assets/image/code2.png" alt="">
                  </div>
                  <div>
                    <div v-for="(item,index) in member" :key="index" class="member">
                      <img :src="item.img">
                      <div style="margin-left: 5px;">
                        <div class="title">{{ item.title }}</div>
                        <div class="content">{{ item.content }}</div>
                        <div class="content">{{ item.content2 }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--              修改密码-->
              <div v-show="active === '4'">
                <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
                         class="demo-ruleForm">
                  <el-form-item label="旧密码" prop="oldPassword">
                    <el-input type="password" v-model="ruleForm.oldPassword" placeholder="请填写旧密码"
                              autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item label="新密码" prop="newPassword">
                    <el-input type="password" v-model="ruleForm.newPassword" placeholder="新密码必须包含（字母，数字）"
                              autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item label="再次确认" prop="checkPass">
                    <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="submitForm()">提交</el-button>
                  </el-form-item>
                </el-form>
              </div>
              <el-dialog
                  title="提示"
                  :visible.sync="dialogVisible2"
                  width="30%">
                <span>即将跳转至业务受理平台，在受理平台中完成业务申请。</span>
                <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible2 = false">取 消</el-button>
    <el-button type="primary" @click="goUrl">确 定</el-button>
  </span>
              </el-dialog>
              <el-dialog
                  title="提示"
                  :visible.sync="dialogVisible"
                  width="30%">
                <span>是否确认退出登录？</span>
                <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="logOut">确 定</el-button>
  </span>
              </el-dialog>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <div style="margin: 20px;"></div>
    </div>
  </div>
</template>
<script>
import axios from '@/plugins/request';
import {MessageBox} from "element-ui";
import store from "@/store";
import config from "@/config"

export default {
  name: "personCenter",
  data() {
    var validateOldPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入就密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validateNewPassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请填写新密码'));
      } else if (!/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,}$/.test(value)) {
        return callback(new Error('密码必须包含字母和数字，且长度至少为6位'));
      } else {
        callback();
      }
    };
    var validateCheckPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入您的密码'));
      } else if (value !== this.ruleForm.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };

    const validateCreditCode = (rule, value, callback) => {
      const creditCodeRegex = /^[0-9A-Z]{18}$/;
      if (!value) {
        return callback(new Error('请填写完整、准确的统一社会信用代码'));
      } else if (!creditCodeRegex.test(value)) {
        return callback(new Error('统一社会信用代码格式有误'));
      } else {
        return callback();
      }
    };

    const validatePhone = (rule, value, callback) => {
      const phoneRegex = /^[1]([3-9])[0-9]{9}$/;
      if (!value) {
        return callback(new Error('请填写联系电话'));
      } else if (!phoneRegex.test(value)) {
        return callback(new Error('请输入有效的手机号'));
      } else {
        return callback();
      }
    };

    const validateEmail = (rule, value, callback) => {
      const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      if (!value) {
        return callback(new Error('请填写联系邮箱'));
      } else if (!emailRegex.test(value)) {
        return callback(new Error('请输入有效的邮箱地址'));
      } else {
        return callback();
      }
    };
    return {
      active: '1',
      navs: [
        {index: '1', title: '申请成为会员单位', icon: 'el-icon-office-building'},
        {index: '2', title: '综合业务办理', icon: 'el-icon-postcard'},
        {index: '3', title: '联系平台客服', icon: 'el-icon-service'},
        {index: '4', title: '修改密码', icon: 'el-icon-lock'},
        {index: '5', title: '退出登录', icon: 'el-icon-switch-button'},
      ],
      services: [
        {
          img: require('../assets/image/red.png'),
          title: '政策便利',
          style: 'color: #DD4040',
          server: ['青岛企业设立', '马来西亚企业设立', 'FDI外资企业设立', '商务签证办理']
        },
        {
          img: require('../assets/image/yellow.png'),
          title: '金融便利',
          style: 'color: #F5E35D',
          server: ['境内FDI/NRA开户', '跨境贸易融资', '马来西亚银行开户', '跨境结算开户']
        },
        {
          img: require('../assets/image/blue.png'),
          title: '贸易便利',
          style: 'color: #2A50B7',
          server: ['国际商机对接', '商会资源对接', '全球企业信用报告', 'RMD国际风险库']
        },
        {
          img: require('../assets/image/white.png'),
          title: '认证便利',
          style: "color:#666666",
          server: ['国际清真认证', 'GCA国际信用认证', '平台信用级别认证']
        }
      ],
      business: [
        {
          img: require("../assets/image/business1.png"),
          title: '贸易伙伴对接',
          description: '帮您对接马来西亚的供应商和采购商。'
        },
        {
          img: require("../assets/image/business2.png"),
          title: '跨境企业设立',
          description: '在马来西亚设立一家跨境企业。'
        },
        {
          img: require("../assets/image/business3.png"),
          title: '报关单融资贷款',
          description: '开通报关单功能即可获得低息贷款。'
        },
        {img: require("../assets/image/business4.png"), title: '跨境贸易融资', description: '申请银行跨境低息融资。'},
        {
          img: require("../assets/image/business5.png"),
          title: '国际清真认证',
          description: '与伊斯兰国家贸易必备认证。'
        },
        {
          img: require("../assets/image/business6.png"),
          title: '全球企业信用报告',
          description: '查询贸易伙伴、竞争对手的信用报告。'
        }
      ],
      member: [
        {img: require("../assets/image/email.png"), title: '联系邮箱', content: 'service@mycntrade.com'},
        {
          img: require("../assets/image/position.png"),
          title: '详细地址',
          content: '山东省青岛市市南区宁夏路288号',
          content2: '青岛软件园11号楼A座611-29'
        },
      ],
      infoForm: {
        companyName: null,
        businessLicenseCode: null,
        location: null,
        contactPerson: null,
        contactPhone: null,
        contactEmail: null,
        contactWebsite: '',
        applicationContent: null,
      },
      infoRules: {
        companyName: [{required: true, message: '请填写完整、准确的企业名称', trigger: 'blur'}],
        businessLicenseCode: [{required: true, validator: validateCreditCode, trigger: 'blur'}],
        location: [{required: true, message: '请填写完整、准确的企业地址', trigger: 'blur'}],
        contactPerson: [{required: true, message: '请填写业务联系人', trigger: 'blur'}],
        contactPhone: [{required: true, validator: validatePhone, trigger: 'blur'}],
        contactEmail: [{required: true, validator: validateEmail, trigger: 'blur'}],
        applicationContent: [{required: true, message: '请填写三个主营跨境商品或服务', trigger: 'blur'}],
      },
      ruleForm: {
        oldPassword: '',
        newPassword: '',
        checkPass: '',
      },
      rules: {
        oldPassword: [
          {required: true, validator: validateOldPassword, trigger: 'blur'}
        ],
        newPassword: [
          {required: true, message: '请填写新密码', trigger: 'blur'},
          {validator: validateNewPassword, trigger: 'blur'}
        ],
        checkPass: [
          {required: true, validator: validateCheckPass, trigger: 'blur'}
        ],
      },
      isWrite: false,
      isApproval: false,
      dialogVisible: false,
      dialogVisible2: false,
    }
  },
  created() {
    if (store.getters.token){
    this.getPersonInfo();
    }else {
      this.$router.push({path: '/login'})
    }
  },
  methods: {
    // 0是未认证 1是审核中 2是审核成功
    getPersonInfo() {
      axios.post(config.redirectUrl + "/api/company/membership/queryMyCompanyDetail").then(res => {
        if (res.data.status == 0) {
          this.isWrite = false;
          this.isApproval = false;
        } else if (res.data.status == '1' || res.data.status == '2') {
          this.isWrite = true;
          this.isApproval = true;
          this.infoForm.companyName = res.data.data.companyName;
          this.infoForm.businessLicenseCode = res.data.data.businessLicenseCode;
          this.infoForm.location = res.data.data.location;
          this.infoForm.contactPerson = res.data.data.contactPerson;
          this.infoForm.contactPhone = res.data.data.contactPhone;
          this.infoForm.contactEmail = res.data.data.contactEmail;
          this.infoForm.contactWebsite = res.data.data.contactWebsite;
          this.infoForm.applicationContent = res.data.data.applicationContent;
        }
      })
    },
    handleSelect(key) {
      if (key === '5') {
        this.dialogVisible = true;
      } else {
        this.active = key;
      }
    },
    writeInfo() {
      this.isWrite = true;
    },
    handleClick() {
      this.dialogVisible2 = true;
    },
    goUrl() {
      this.dialogVisible2 = false;
      const token = store.getters.token;
      if (token) {
        window.open(config.redirectUrl + "/api/redirect/redirectToTrade?token=" + token)
      } else {
        window.open("https://platform.mycntrade.com");
      }
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = new FormData();
          data.append("oldPassword", this.ruleForm.oldPassword);
          data.append("newPassword", this.ruleForm.newPassword);
          axios.post(config.redirectUrl + "/api/user/resetPassword", data).then(res => {
            if (res.data.code === 0) {
              this.$message.success(res.data.msg);
              this.$store.commit("clearToken");
              this.$router.push({path: '/login'});
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      });
    },
    logOut() {
      axios.post(config.redirectUrl + "/api/user/logout").then(res => {
        if (res.data.code === 0) {
          this.dialogVisible = false;
          this.$store.commit("clearToken");
          this.$router.push({path: '/homePage'})
        }
      })
    },
    submitHandle() {
      this.$refs.infoForm.validate((valid) => {
        if (valid) {
          MessageBox.confirm('是否确定正式提交申请？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let data = new FormData();
            data.append("companyName", this.infoForm.companyName);
            data.append("businessLicenseCode", this.infoForm.businessLicenseCode);
            data.append("location", this.infoForm.location);
            data.append("contactPerson", this.infoForm.contactPerson);
            data.append("contactPhone", this.infoForm.contactPhone);
            data.append("contactEmail", this.infoForm.contactEmail);
            data.append("contactWebsite", this.infoForm.contactWebsite);
            data.append("applicationContent", this.infoForm.applicationContent);
            axios.post(config.redirectUrl + "/api/company/membership/application", data).then(res => {
              if (res.data.code === 0) {
                this.isWrite = true;
                this.isApproval = true;
                this.$message.success("申请提交成功")
              }
            })
          }).catch(() => {
            this.$message.info("操作取消");
          });
        }
      })
    }
  }
}
</script>


<style scoped lang="scss">
.title {
  font-weight: 600;
  font-size: 20px;
  color: #666666;
}

.subText {
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  margin-left: 10px;
}

.el-menu-item {
  text-align: center;
  margin-right: 20px;
}

.el-menu {
  border-right: none;
}

.member {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-left: 30px;

  .title {
    font-size: 16px;
    color: #666666;
  }

  .content {
    font-size: 14px;
    color: #999999;
  }
}

.business {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 calc(50% - 40px);
  border: solid 1px #DBDBDB;
  border-radius: 6px;
  margin: 20px;
  padding: 20px;
}

.el-form-item {
  width: 460px;
  margin: 0 auto 22px;
}

ul {
  padding: 0 20px;
}

.myli {
  font-size: 14px;
  line-height: 26px;
}

.el-menu-item {
  text-align: left;
}

.myAppHandle {
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
}
</style>
