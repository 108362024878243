// store/index.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        username: localStorage.getItem('username') || '',
        token: localStorage.getItem('token') || '',
        isLoggedIn: !!localStorage.getItem('token'),
        breadcrumb: localStorage.getItem('breadcrumb') || '',
        activeIndex: localStorage.getItem('activeIndex') || '/homePage'

    },
    mutations: {
        setUsername(state, username) {
            state.username = username;
            localStorage.setItem('username', username);
        },
        setToken(state, token) {
            state.token = token;
            state.isLoggedIn = true;
            localStorage.setItem('token', token);
        },
        clearToken(state) {
            state.token = '';
            state.isLoggedIn = false;
            localStorage.removeItem('token');
        },
        setBreadcrumb(state, breadcrumb) {
            state.breadcrumb = breadcrumb;
            localStorage.setItem("breadcrumb", breadcrumb);
        },
        setActiveIndex(state, activeIndex) {
            state.activeIndex = activeIndex;
            localStorage.setItem("activeIndex", activeIndex);
        }
    },
    actions: {
        name({commit}, username) {
            commit('setUsername', username);
        },
        login({commit}, token) {
            commit('setToken', token);
        },
        logout({commit}) {
            commit('clearToken');
        },
        breadcrumb({commit}, breadcrumb) {
            commit('setBreadcrumb', breadcrumb);
        },
        activeIndex({commit}, activeIndex) {
            commit('setActiveIndex', activeIndex);
        }
    },
    getters: {
        username: state => state.username,
        token: state => state.token,
        isLoggedIn: state => state.isLoggedIn,
        breadcrumb: state => state.breadcrumb,
        activeIndex: state => state.activeIndex,
    }
});
