import Vue from 'vue'
import App from './App.vue'

import '@/assets/styles/index.scss' // global css

import store from './store'
import router from './router'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

import Echart from './components/echart/index.vue'

import axios from "axios";
Vue.prototype.$axios = axios;

Vue.component("Echart", Echart)

Vue.config.productionTip = false

new Vue({
    el: '#app',
    store,
    router,
    render: h => h(App),
}).$mount('#app')
