var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:_vm.ref,staticClass:"dv-scroll-ranking-board"},_vm._l((_vm.rows),function(item,i){return _c('div',{key:item.toString() + item.scroll},[_c('div',{staticClass:"nowType",staticStyle:{"align-items":"baseline"},style:(`height: ${_vm.heights[i]}px;`)},[_c('div',{staticClass:"row-item",staticStyle:{"width":"100%"},style:(`height: ${_vm.heights[i]}px;`)},[_c('div',{staticClass:"ranking-info",staticStyle:{"display":"unset"}},[_c('div',{staticClass:"rank",staticStyle:{"font-weight":"bold"},style:({
            width: '100px',
             color: item.type === '进口' ? '#333' :
           item.type === '出口' ? '#333' :
           item.type === '进出口' ? '#67dddc' :
           '#fff'
          })},[_vm._v(_vm._s(item.type)+"No."+_vm._s(item.number)+" ")]),_c('div',{staticClass:"info-name",staticStyle:{"color":"#666666"},domProps:{"innerHTML":_vm._s(item.name)}}),_c('div',{staticClass:"ranking-value",staticStyle:{"color":"#666666"}})]),_c('div',{staticClass:"ranking-column"},[_c('div',{staticClass:"inside-column",style:(`width: ${item.percent}%;background: ${_vm.bgColor(
            item.number
          )}`)},[_c('div',{staticClass:"shine"})])])])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }