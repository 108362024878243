<template>
  <div class="app-container">
    <div style="padding: 0 12%">
      <div style="margin-top: 20px;">
        <el-form :model="queryProductParams" ref="queryShopForm" size="small" :inline="true" label-width="68px">
          <el-form-item label="搜索商品">
          </el-form-item>
          <el-form-item prop="companyCountry">
            <el-select v-model="queryProductParams.companyCountry" filterable clearable placeholder="全部"
                       style="width: 170px">
              <el-option
                  v-for="(item,index) in countryType"
                  :key="index"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="productName">
            <el-input
                v-model="queryProductParams.goodsName"
                placeholder="请输入产品名称"
                clearable
                style="width: 180px"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="getProductList">搜索</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-card shadow="never">
        <div slot="header" class="clearfix">
          <div>
            <breadcrumb style="margin-bottom: 20px;"></breadcrumb>
            <span class="el-card-title" style="margin-left: 10px;">商品查询结果</span>
            <span class="el-card-describe">Product Search Result</span>
          </div>
        </div>
        <div class="cardList" v-if="productList.length > 0">
          <div v-for="(item,index) in productList" :key="index" @click="lookDetail(item)">
            <img src="../assets/image/china.png" v-if="item.companyCountry === '1'"
                 style="position: relative;top: 14px;right: 72px;">
            <img src="../assets/image/malai.png" v-if="item.companyCountry === '2'"
                 style="position: relative;top: 16px;right: 70px;">
            <img :src="item.goodsLogo" style="height: 165px;width: 165px;border-radius: 6px;cursor: pointer;">
            <div style="width: 165px;margin: 0 auto;">
              <div class="goodsName" :title="item.goodsName">{{ item.goodsName }}</div>
              <div class="address">
                <img src="../assets/image/address.png" style="height: 20px;width: 20px;">
                <span>{{ item.originPlace }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else><el-empty description="没有找到对应查询结果"></el-empty></div>
        <pagination
            v-show="total>0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="getProductList"
        />
      </el-card>
      <div style="margin: 20px;"></div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/public/pagination.vue";
import breadcrumb from "@/components/public/breadcrumb.vue";
import config from "@/config";

export default {
  name: "result.vue",
  components: {breadcrumb, Pagination},
  data() {
    return {
      queryProductParams: {
        companyCountry: '',
        goodsName: ''
      },
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 50
      },
      productList: [],
      countryType: [
        {label: '全部', value: ''},
        {label: '中国', value: 1},
        {label: '马来西亚', value: 2}
      ],
    }
  },
  created() {
    let breadcrumb = [
      {name: '首页', path: '/homePage'},
      {name: '商品查询结果', path: '/productResult'},
    ];
    this.$store.commit("setBreadcrumb", breadcrumb);
    let queryShopParams = window.localStorage.getItem("queryShopParams");
    if (queryShopParams) {
      this.queryProduct(queryShopParams);
    }
  },
  methods: {
    queryProduct(value) {
      this.queryProductParams = JSON.parse(value);
      this.getProductList();
    },
    getProductList() {
      let data = new FormData();
      data.append("companyCountry", this.queryProductParams.companyCountry)
      data.append("goodsName", this.queryProductParams.goodsName)
      data.append("pageNum", this.queryParams.pageNum)
      data.append("pageSize", this.queryParams.pageSize)
      this.$axios.post(config.redirectUrl + "/api/goods/queryGoodsList", data).then(res => {
        if (res.data.data.code === 0) {
          this.total = res.data.data.total
          this.productList = res.data.data.rows
        }
      })
    },
    lookDetail(productInfo) {
      window.localStorage.setItem("goodsId", JSON.stringify(productInfo.goodsId));
      window.open(window.location.origin + "/productDetails")
    }
  }
}
</script>

<style scoped lang="scss">
.bannerStyle {
  background: #2a50b7;
}

.cardList {
  display: flex;
  flex-wrap: wrap;

  .goodsName {
    font-size: 14px;
    font-weight: 600;
    color: #306FC7;
    line-height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 165px;
    display: inline-block;
    margin-top: 10px;
    cursor: pointer;
  }

  .address {
    font-size: 12px;
    font-weight: 400;
    color: #F5AF5D;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.cardList > div {
  flex: 0 0 20%; // 设置每个子元素占用20%的宽度
  max-width: 20%; // 设置最大宽度为20%，防止占用超过分配的空间
  display: flex;
  flex-direction: column; // 子元素内部使用列布局
  align-items: center; // 子元素内部居中对齐
  margin-bottom: 10px; // 根据需要调整，给下方元素留出空间
}

.type {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px; /* 新增的对应调整，为了使边缘对齐 */
}

.member-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 calc(50% - 40px);
  border: solid 1px #DDDDDD;
  border-radius: 6px;
  padding: 40px;
  margin: 20px;
}

.name {
  font-size: 20px;
  color: #306FC7;
  line-height: 35px;
}

.subTitle {
  color: #666666;
  font-size: 13px;
  line-height: 20px;
}

.desc {
  font-size: 13px;
  color: #666666;
  line-height: 20px;
}

.corner-image {
  position: absolute;
  top: 5px;
  right: 5px;
}

.loginBntBox {
  width: 95px;
  height: 45px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #306FC7;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 45px;
  color: #306FC7;
  cursor: pointer;
}

::v-deep .pagination-container .el-pagination {
  right: 15% !important;
  position: absolute;
}
</style>
