<template>
  <div class="app-container">
    <div style="display: flex;justify-content: center;margin-top: 20px;">
      <el-form :model="queryShopParams" ref="queryShopForm" size="small" :inline="true" label-width="68px">
        <el-form-item label="搜索商品">
        </el-form-item>
        <el-form-item prop="companyCountry">
          <el-select v-model="queryShopParams.companyCountry" filterable clearable placeholder="全部"
                     style="width: 100px">
            <el-option
                v-for="(item,index) in countryType"
                :key="index"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="productName">
          <el-input
              v-model="queryShopParams.goodsName"
              placeholder="请输入产品名称"
              clearable
              style="width: 180px"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="handleShopQuery">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-form :model="queryEntParams" ref="queryEntForm" size="small" :inline="true" label-width="68px"
               style="margin-left: 40px;">
        <el-form-item label="搜索公司">
        </el-form-item>
        <el-form-item prop="companyCountry">
          <el-select v-model="queryEntParams.companyCountry" filterable clearable placeholder="全部"
                     style="width: 100px">
            <el-option
                v-for="(item,index) in countryType"
                :key="index"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="companyName">
          <el-input
              v-model="queryEntParams.companyName"
              placeholder="请输入企业名称"
              clearable
              style="width: 180px"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="handleEntQuery">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "query",
  data() {
    return {
      queryShopParams: {
        companyCountry: '',
        goodsName: ''
      },
      countryType: [
        {label: '全部', value: ''},
        {label: '中国', value: 1},
        {label: '马来西亚', value: 2}
      ],
      queryEntParams: {
        companyCountry: '',
        companyName: ''
      },
    }
  },
  created() {
  },
  methods: {
    handleShopQuery() {
      if (this.$router.currentRoute.path !== '/productresult') {
        window.localStorage.setItem("queryShopParams", JSON.stringify(this.queryShopParams))
        this.$router.push({path: '/productresult'})
      }
    },
    handleEntQuery() {
      if (this.$router.currentRoute.path !== '/companyResult') {
        window.localStorage.setItem("queryEntParams", JSON.stringify(this.queryEntParams))
        this.$router.push({path: '/companyResult'})
      }
    },
  }
}
</script>

<style scoped lang="scss">

</style>
