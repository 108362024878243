<template>
  <div class="center_top">
    <div class="topLeftText">
      <div class="type greenColor"> {{ data.name }}</div>
      <div style="margin-left: 10px;">
        <div
            class="greenText">
          {{ data.value }}
        </div>
        <div class="subText">自2024年1月起累计，单位（亿）美元</div>
      </div>
    </div>
    <Echart
        ref="charts"
        :options="options"
        id="topLeftChart"
        class="echarts_top"
    ></Echart>
  </div>
</template>

<script>
import importTrend from "@/public/importTrend.json"

export default {
  data() {
    return {
      options: {},
      data: {},
    };
  },
  created() {
    this.data = importTrend;
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.init(this.data)
    },
    init(newData) {
      this.options = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,.6)",
          borderColor: "rgba(147, 235, 248, .8)",
          textStyle: {
            color: "#FFF",
          },
          formatter: function (params) {
            var result = params[0].name + "<br>";
            result +=
                params[0].marker +
                " " +
                params[0].seriesName +
                " : " +
                params[0].value +
                "亿美元</br>";
            return result;
          },
        },
        grid: {
          left: "8%",
          right: "0%",
          bottom: "28px",
          top: "40px",
        },
        xAxis: {
          data: newData.xdata,
          axisTick: {
            show: false, // 这里设置为false隐藏x轴的刻度线（小锯齿）
          },
          axisLine: {
            lineStyle: {
              color: "#666666",
            },
          },
        },
        yAxis:
            {
              name: '(亿)美元',
              axisLabel: {
                formatter: "{value}",
              },
              axisLine: {
                lineStyle: {
                  color: "#666666",
                },
              },
            },
        series: [
          {
            name: newData.name,
            type: "line",
            smooth: true,
            showAllSymbol: true,
            symbol: "emptyCircle",
            symbolSize: 8,
            yAxisIndex: 0,
            itemStyle: {
              color: newData.color,
            },
            data: newData.ydata,
            markPoint: {
              data: [
                {type: 'max', name: '最高值'},
                {type: 'min', name: '最低值'}
              ],
              symbolSize: 50,
              label: {
                color: "black", // 设置标记点文本颜色为白色
                formatter: '{c}'
              }
            },
            markLine: {
              data: [
                {type: 'average', name: '平均值'}
              ],
              label: {
                color: "white",
                position: "end", // 标签位置，在线的末端，即上方
                formatter: "{c}" // 标签格式，{b}为标记线名称，{c}为数值
              }
            }
          },
        ],
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.center_top {
  width: 100%;
  height: 240px;

  .echarts_top {
    width: 100%;
    height: 100%;
  }
}

.topLeftText {
  display: flex;
  align-items: center;

  .type {
    font-size: 30px;
    border-radius: 5px;
    padding: 5px 10px;
  }

  .greenColor {
    color: #FFFFFF;
    background: linear-gradient(to bottom, #00d98b, #009b63);
    font-size: 24px;
    padding: 10px;
  }

  .greenText {
    color: #00D98B;
    font-size: 32px;
  }

  .subText {
    font-size: 14px;
    margin-top: 5px;
  }
}
</style>
