<template>
  <div class="app-container">
    <div style="margin:20px;"></div>
    <div style="padding: 0 12%">
      <el-row :gutter="20">
        <el-col :span="20">
          <el-card shadow="never">
            <div slot="header" class="clearfix">
              <breadcrumb style="margin-bottom: 20px;"></breadcrumb>
              <span class="el-card-title">企业详情</span>
              <span class="el-card-describe">Company Details</span>
            </div>
            <div style="border: solid 1px #DBDBDB;border-radius:6px;padding: 40px;">
              <div style="display: flex;align-items: center;justify-content: space-between;">
                <div class="entTitle">{{ companyInfo.companyName }}</div>
                <div v-if="companyInfo.companyCountry === '1'" style="display: flex;align-items: center;">
                  <img src="../assets/image/china.png" style="width: 36px;"/>
                  <div class="countryType">中国大陆企业</div>
                </div>
                <div v-if="companyInfo.companyCountry === '2'" style="display: flex;align-items: center;">
                  <img src="../assets/image/malai.png" style="width: 36px;"/>
                  <div class="countryType">马来西亚企业</div>
                </div>
              </div>
              <el-divider></el-divider>
              <div class="entDesc">营业执照：{{ companyInfo.businessLicenseCode }}</div>
              <div class="entDesc">法定代表人：{{ companyInfo.legalRepresentative }}</div>
              <div class="entDesc">注册资本：{{ companyInfo.registeredCapital }}万人民币</div>
              <div class="entDesc">电话：{{ companyInfo.contactPhone }}</div>
              <div class="entDesc">邮箱：{{ companyInfo.contactEmail }}</div>
              <div class="entDesc">网址：{{ companyInfo.contactWebsite }}</div>
              <div class="entDesc">地址：{{ companyInfo.companyAddress }}</div>
              <el-divider></el-divider>
              <div style="line-height: 28px;" v-html="companyInfo.companyProfile"></div>
              <el-divider></el-divider>
              <div style="display: flex;flex-wrap: wrap;">
                <div class="entFlag" v-if="companyInfo.certificationMark.includes('1')">
                  <img src="../assets/image/ent1.png" alt="">
                  <div>
                    <div class="entType">企业已通过营业执照审核</div>
                    <div class="entType" style="color: #999999">该企业已经上传营业执照，并通过平台系统审核。</div>
                  </div>
                </div>
                <div class="entFlag" v-if="companyInfo.certificationMark.includes('2')">
                  <img src="../assets/image/ent2.png" alt="">
                  <div>
                    <div class="entType">海关已验证报关单真实性</div>
                    <div class="entType" style="color: #999999">
                      该企业开通了海关报关单服务并通过海关审核，贸易背景真实可信。
                    </div>
                  </div>
                </div>
                <div class="entFlag" v-if="companyInfo.certificationMark.includes('3')">
                  <img src="../assets/image/ent3.png" alt="">
                  <div>
                    <div class="entType">企业信用报告可在线查询</div>
                    <div class="entType" style="color: #999999">该企业的企业信用报告可通过平台公开查询、购买。</div>
                  </div>
                </div>
                <div class="entFlag" v-if="companyInfo.certificationMark.includes('4')">
                  <img src="../assets/image/ent4.png" alt="">
                  <div>
                    <div class="entType">企业已完成国际HALAL认证</div>
                    <div class="entType" style="color: #999999">
                      该企业旗下的产品完成了国际清真认证，可与伊斯兰国家进行贸易。
                    </div>
                  </div>
                </div>
                <div class="entFlag" v-if="companyInfo.certificationMark.includes('5')">
                  <img src="../assets/image/ent5.png" alt="">
                  <div>
                    <div class="entType">已通过GCA国际信用认证</div>
                    <div class="entType" style="color: #999999">该企业获得GCA国际信用认证，贸易风险可控。</div>
                  </div>
                </div>
                <div class="entFlag" v-if="companyInfo.certificationMark.includes('6')">
                  <img src="../assets/image/ent6.png" alt="">
                  <div>
                    <div class="entType">成功通过RMD全球风险排查</div>
                    <div class="entType" style="color: #999999">该企业未出现在RMD全球企业风险库中，排除重大风险。</div>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
          <div style="margin: 20px;"></div>
          <el-card shadow="never" v-if="companyGoods.length > 0">
            <div slot="header" class="clearfix">
              <span class="el-card-title">贸易产品概览</span>
              <span class="el-card-describe">Overview of Trade Products</span>
            </div>
            <div style="display: flex; justify-content: space-around;">
              <div v-for="(item, index) in companyGoods" :key="index" style="cursor: pointer;" @click="lookDetail(item)">
                <div style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                  <img :src="item.goodsLogo" style="width: 160px; border-radius: 6px;" alt="">
                  <div class="product">{{ item.goodsName }}</div>
                </div>
              </div>
            </div>
          </el-card>
          <div style="margin: 20px;"></div>
        </el-col>
        <el-col :span="4">
          <div style="display: flex;flex-direction: column">
            <productTypes></productTypes>
            <memberService></memberService>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import memberService from "@/components/public/memberServices.vue";
import productTypes from "@/components/public/productTypes.vue";
import breadcrumb from "@/components/public/breadcrumb.vue";
import axios from '@/plugins/request';
import config from "@/config";

export default {
  name: "companyDetails",
  components: {breadcrumb, productTypes, memberService},
  data() {
    return {
      companyId: null,
      companyInfo: {},
      companyGoods: []
    }
  },
  created() {
    let breadcrumb = [
      {name: '首页', path: '/homePage'},
      {name: '企业详情', path: '/companyDetails'},
    ];
    this.$store.commit("setBreadcrumb", breadcrumb);
    this.companyId = JSON.parse(window.localStorage.getItem("companyId"));
    this.getCompanyInfo();
    this.getCompanyGoods();
  },
  methods: {
    getCompanyInfo() {
      let data = new FormData();
      data.append("companyId", this.companyId);
      axios.post(config.redirectUrl + "/api/company/membership/queryCompanyDetail", data).then(res => {
        if (res.data.code === 0) {
          this.companyInfo = res.data.data
        }
      })
    },
    getCompanyGoods() {
      let data = new FormData();
      data.append("companyId", this.companyId);
      this.$axios.post(config.redirectUrl + "/api/goods/queryCompanyGoodsList", data).then(res => {
        if (res.data.code === 0) {
          if (res.data.data.length > 5) {
            this.companyGoods = res.data.data.slice(0, 5);
          } else {
            this.companyGoods = res.data.data
          }
        }
      })
    },
    lookDetail(productInfo) {
      window.localStorage.setItem("goodsId", JSON.stringify(productInfo.goodsId));
      window.open(window.location.origin + "/productDetails")
    },
  }
}
</script>
<style scoped lang="scss">
.entTitle {
  font-weight: bold;
  font-size: 24px;
  color: #306FC7;
  line-height: 55px;
}

.countryType {
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  line-height: 26px;
  margin-left: 5px;
}

.entDesc {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 26px;
}

.entType {
  font-weight: 600;
  font-size: 14px;
  color: #306FC7;
  line-height: 26px;
  margin-left: 20px;
}

.product {
  font-weight: 600;
  font-size: 14px;
  color: #306FC7;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 165px;
  display: inline-block;
}

.entFlag {
  display: flex;
  align-items: center;
  flex: 0 0 50%;
  margin-bottom: 20px;
}
</style>
