<template>
  <div class="app-container">
    <div style="margin:20px;"></div>
    <div style="padding: 0 12%">
      <el-row :gutter="20">
        <el-col :span="4">
          <div style="display: flex;flex-direction: column">
            <productTypes @valueFormType="valueFormType"></productTypes>
            <memberService></memberService>
          </div>
        </el-col>
        <el-col :span="20">
          <el-card shadow="never">
            <div slot="header" class="clearfix">
              <div>
                <breadcrumb style="margin-bottom: 20px;"></breadcrumb>
                <span class="el-card-title">{{ productTypesName }}</span>
                <span class="el-card-describe">Products List</span>
              </div>
            </div>
            <div class="cardList">
              <div v-for="(item,index) in shopList" :key="index" @click="lookDetail(item)">
                <img src="../assets/image/china.png" v-if="item.companyCountry === '1'"
                     style="position: relative;top: 14px;right: 72px;">
                <img src="../assets/image/malai.png" v-if="item.companyCountry === '2'"
                     style="position: relative;top: 16px;right: 70px;">
                <img :src="item.goodsLogo" style="height: 165px;width: 165px;border-radius: 6px;cursor: pointer;">
                <div style="width: 165px;margin: 0 auto;">
                  <div class="goodsName" :title="item.goodsName">{{ item.goodsName }}</div>
                  <div class="address">
                    <img src="../assets/image/address.png" style="height: 20px;width: 20px;">
                    <span>{{ item.originPlace }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div style="display: unset;">
              <pagination
                  v-show="total>0"
                  :total="total"
                  :page.sync="queryParams.pageNum"
                  :limit.sync="queryParams.pageSize"
                  @pagination="getProductsTypeList"
              />
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div style="margin:20px;"></div>
  </div>
</template>
<script>
import breadcrumb from "@/components/public/breadcrumb.vue";
import productTypes from "@/components/public/productTypes.vue";
import memberService from "@/components/public/memberServices.vue";
import Pagination from "@/components/public/pagination.vue";
import FormData from "form-data";
import config from "@/config"

export default {
  name: "productsType",
  components: {
    Pagination,
    breadcrumb, productTypes, memberService
  },
  data() {
    return {
      productTypesName: null,
      total: 0,
      queryParams: {
        cateId: '',
        pageNum: 1,
        pageSize: 50
      },
      shopList: [],
    }
  },
  created() {
    this.$store.commit("setActiveIndex","/homePage");
    this.queryParams.cateId = window.localStorage.getItem("productTypesId");
    this.productTypesName = window.localStorage.getItem("productTypesName");
    let breadcrumb = [
      {name: '首页', path: '/homePage'},
      {name: this.productTypesName, path: '/productsType'},
    ];
    this.$store.commit("setBreadcrumb", breadcrumb);
    this.getProductsTypeList();
  },
  watch: {
    productTypesName(newVal) {
      let breadcrumb = [
        {name: '首页', path: '/homePage'},
        {name: newVal, path: '/productsType'},
      ];
      this.$store.commit("setBreadcrumb", breadcrumb);
    }
  },
  methods: {
    getProductsTypeList() {
      let data = new FormData();
      data.append('cateId', this.queryParams.cateId);
      data.append('pageNum', this.queryParams.pageNum);
      data.append('pageSize', this.queryParams.pageSize);
      this.$axios.post(config.redirectUrl + "/api/goods/queryGoodsList", data).then(res => {
        if (res.data.data.code === 0) {
          this.shopList = res.data.data.rows;
          this.total = res.data.data.total;
        }
      })
    },
    valueFormType(value) {
      let data = JSON.parse(value)
      this.queryParams.cateId = data.productTypesId;
      this.productTypesName = data.productTypesName;
      this.getProductsTypeList();
    },
    lookDetail(productInfo) {
      window.localStorage.setItem("goodsId", JSON.stringify(productInfo.goodsId));
      window.open(window.location.origin + "/productDetails")
    },
  }
}
</script>

<style scoped lang="scss">
.cardList {
  display: flex;
  flex-wrap: wrap;

  .goodsName {
    font-size: 14px;
    font-weight: 600;
    color: #306FC7;
    line-height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 165px;
    display: inline-block;
    margin-top: 10px;
    cursor: pointer;
  }

  .address {
    font-size: 12px;
    font-weight: 400;
    color: #F5AF5D;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.cardList > div {
  flex: 0 0 20%; // 设置每个子元素占用20%的宽度
  max-width: 20%; // 设置最大宽度为20%，防止占用超过分配的空间
  display: flex;
  flex-direction: column; // 子元素内部使用列布局
  align-items: center; // 子元素内部居中对齐
  margin-bottom: 10px; // 根据需要调整，给下方元素留出空间
}
</style>
