import Vue from 'vue'
import Router from 'vue-router'

import Layout from '../components/Layout.vue' // 布局
import homePage from '@/views/homePage.vue' // 首页
import importProducts from '@/views/importProducts.vue' // 进口商品
import exportProducts from '@/views/exportProducts.vue' // 出口商品
import memberDirectory from '@/views/memberDirectory.vue' // 会员单位名录
import businessInsights from '@/views/businessInsights.vue' // 商机洞察
import productDetails from '@/views/productDetails.vue'; // 商品详情
import companyDetails from '@/views/companyDetails.vue' // 企业详情
import login from '@/views/login.vue'; // 登录
import register from '@/views/register.vue'; // 注册
import personCenter from '@/views/personCenter.vue'; // 个人中心
import productResult from '@/views/productResult.vue';
import companyResult from '@/views/companyResult.vue';
import productsType from '@/views/productsType.vue';

Vue.use(Router)

const routes = [
    {
        path: '/',
        component: Layout,
        redirect: "/homePage",
        children: [
            {
                path: "/homePage",
                component: homePage,
                meta: {
                    title: "贸易市场首页"
                }
            },
            {
                path: "/importProducts",
                component: importProducts,
                meta: {
                    title: "进口商品"
                }
            },
            {
                path: "/exportProducts",
                component: exportProducts,
                meta: {
                    title: "出口商品"
                }
            },
            {
                path: "/memberDirectory",
                component: memberDirectory,
                meta: {
                    title: "会员单位名录"
                }
            },
            {
                path: "/businessInsights",
                component: businessInsights,
                meta: {
                    title: "商机洞察"
                }
            },
            {
                path: "/personCenter",
                component: personCenter,
                meta: {
                    title: "个人中心",
                }
            },
            {
                path: "/productDetails",
                component: productDetails,
                meta: {
                    title: "商品详情"
                }
            },
            {
                path: "/companyDetails",
                component: companyDetails,
                meta: {
                    title: "企业详情"
                }
            },
            {
                path: "/productResult",
                component: productResult,
                meta: {
                    title: "商品查询结果"
                }
            },
            {
                path: "/companyResult",
                component: companyResult,
                meta: {
                    title: "企业查询结果"
                }
            },
            {
                path: "/productsType",
                component: productsType,
                meta: {
                    title: "商品分类"
                }
            },
        ]
    },
    {
        path: '/login',
        component: login,
        meta: {
            title: "登录"
        }
    },
    {
        path: '/register',
        component: register,
        meta: {
            title: "注册"
        }
    },
]

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {x: 0, y: 0};
        }
    }
})

export default router
