<template>
  <div class="app-container">
    <div class="bannerStyle">
      <img src="../assets/image/banner/banner05.png" alt="" class="bannerItem"/>
      <div class="bannerItem-text">
        <div class="bannerItem-text-title">商机洞察</div>
        <div class="bannerItem-text-content">中马商机无限连接，携手共筑繁荣未来。</div>
      </div>
    </div>
    <div style="margin:20px;"></div>
    <div style="padding: 0 12%">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-card shadow="never">
            <div slot="header" class="clearfix">
              <span class="el-card-title">资料下载</span>
              <span class="el-card-describe">File Download</span>
            </div>
            <div style="display: flex;flex-direction: column;">
              <img src="../assets/image/download1.png" style="cursor: pointer;margin-bottom: 20px;"
                   @click="downloadFile('1')">
              <img src="../assets/image/download2.png" style="cursor: pointer;margin-bottom: 20px;"
                   @click="downloadFile('2')">
              <img src="../assets/image/download3.png" style="cursor: pointer;margin-bottom: 20px;"
                   @click="downloadFile('3')">
              <img src="../assets/image/download4.png" style="cursor: pointer;margin-bottom: 20px;"
                   @click="downloadFile('4')">
              <img src="../assets/image/download5.png" style="cursor: pointer;margin-bottom: 20px;"
                   @click="downloadFile('5')">
              <img src="../assets/image/download6.png" style="cursor: pointer;" @click="downloadFile('6')">
            </div>
          </el-card>
        </el-col>
        <el-col :span="18">
          <el-card shadow="never">
            <div slot="header" class="clearfix">
              <breadcrumb style="margin-bottom: 20px;"></breadcrumb>
              <span class="el-card-title">中国-马来西亚贸易概况</span>
              <span class="el-card-describe">Overview of China-Malaysia Trade</span>
            </div>
            <div>
              <el-row :gutter="20">
                <el-col :span="12" style="padding: 0 20px 0 40px;">
                  <h3>2024年中国从马来西亚进口品类Top5</h3>
                  <importTop5></importTop5>
                </el-col>
                <el-col :span="12" style="padding: 0 40px 0 20px;">
                  <h3>2024年中国向马来西亚出口品类Top5</h3>
                  <exportTop5></exportTop5>
                </el-col>
              </el-row>
            </div>
            <div style="height: 300px;margin-top: 20px;">
              <el-row :gutter="20">
                <el-col :span="12" style="padding: 0 20px 0 40px;">
                  <h3>2024年中国从马来西亚进口贸易走势</h3>
                  <importTrend></importTrend>
                </el-col>
                <el-col :span="12" style="padding: 0 40px 0 20px;">
                  <h3>2024年中国向马来西亚出口贸易走势</h3>
                  <exportTrend></exportTrend>
                </el-col>
              </el-row>
            </div>
            <div style="margin-top: 120px;padding: 0 40px;">
              <h3>2024年马来西亚海关贸易额走势</h3>
              <Echart
                  ref="charts"
                  :options="option3"
                  height="300px"
              ></Echart>
            </div>
            <div style="padding: 0 40px;margin-top: 40px;">
              <h3>马来西亚全国外贸市场调研</h3>
              <div style="display: flex;flex-wrap: wrap">
                <div v-for="(item,index) in market" :key="index" style=" flex: 0 0 calc(25% - 40px);margin: 20px">
                  <div class="number">{{ item.number }}</div>
                  <div class="keyword">关键词：{{ item.keyword }}</div>
                  <div class="desc">{{ item.desc }}</div>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div style="margin: 20px"></div>
  </div>
</template>

<script>
import importTop5 from "@/components/datav/importTop5.vue";
import exportTop5 from "@/components/datav/exportTop5.vue";
import importTrend from "@/components/datav/importTrend.vue";
import exportTrend from "@/components/datav/exportTrend.vue";
import breadcrumb from "@/components/public/breadcrumb.vue";

export default {
  name: "businessInsights",
  components: {breadcrumb, importTop5, exportTop5, importTrend, exportTrend},
  data() {
    return {
      option3: {},
      market: [
        {
          number: '67%',
          keyword: '市场准入',
          desc: '67%的贸易企业表示，除了定位/分析市场的信息有限外，在目标市场获得正确的联系是最大的挑战。'
        },
        {
          number: '70%',
          keyword: '科技',
          desc: '70%的贸易企业没有采用电子商务，原因是缺乏开发、支持和维护该系统的合格人员；其次是与业务无关。'
        },
        {number: '56%', keyword: '监管', desc: '56%的贸易企业表示，处理进口国的法律和海关规定是一个主要困难。'},
        {
          number: '59%',
          keyword: '标准与认证',
          desc: '59%的贸易企业表示，与外国标准和认证打交道是一个困难。总的来说，这是出口商面临的第二大困难。'
        },
        {number: '60%', keyword: '金融', desc: '60%的贸易企业表示他们需要与融资有关的援助。'},
        {number: '35%', keyword: '市场准入', desc: '35%的贸易企业表示，他们对自由贸易协定缺乏了解，需要进一步的帮助。'},
        {number: '40%', keyword: '会员服务', desc: '40%的贸易企业不是任何商会或行业协会的成员。'},
        {number: '30%', keyword: '可持续性', desc: '30%的贸易企业认为可持续实践与他们的行业无关。'},
      ]
    }
  },
  created() {
    let breadcrumb = [
      {name: '首页', path: '/homePage'},
      {name: '商机洞查', path: '/businessInsights'},
    ];
    this.$store.commit("setBreadcrumb", breadcrumb);
  },
  mounted() {
    this.init3();
  },
  methods: {
    init3() {
      this.option3 = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['进口', '出口', '进出口']
        },
        grid: {
          left: '0%',
          right: '1%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['1月', '2月', '3月', '4月']
        },
        yAxis: {
          type: 'value',
          name: 'Value RM million',
        },
        series: [
          {
            name: '进口',
            type: 'line',
            stack: 'Total',
            data: [112237.96, 100116.36, 115845.33, 107017.36]
          },
          {
            name: '出口',
            type: 'line',
            stack: 'Total',
            data: [122410.48, 111356.90, 128564.53, 114719.22]
          },
          {
            name: '进出口',
            type: 'line',
            stack: 'Total',
            data: [234648.45, 211473.27, 244409.86, 221736.58]
          }
        ]
      };
    },
    downloadFile(value) {
      if (value === '1') {
        window.open("https://mycntrade.oss-cn-qingdao.aliyuncs.com/zl_platform/file/1717407789176/马来西亚投资机遇.pdf");
      } else if (value === '2') {
        window.open("https://mycntrade.oss-cn-qingdao.aliyuncs.com/zl_platform/file/1719995873068/马来西亚贸易指南_2023年_.pdf")
      } else if (value === '3') {
        window.open("https://mycntrade.oss-cn-qingdao.aliyuncs.com/zl_platform/file/1717407900980/马来西亚国家贸易蓝图.pdf");
      } else if (value === '4') {
        window.open("https://mycntrade.oss-cn-qingdao.aliyuncs.com/zl_platform/file/1717408483135/马来西亚对外贸易统计公报20240520.pdf");
      } else if (value === '5') {
        window.open("https://mycntrade.oss-cn-qingdao.aliyuncs.com/zl_platform/file/1717408751980/马来西亚2024年3月各州进出口统计数据.pdf");
      } else if (value === '6') {
        window.open("https://mycntrade.oss-cn-qingdao.aliyuncs.com/zl_platform/file/1717408807596/马来西亚2024年第一季度国际收支平衡表.xlsx");
      }
    }
  }
}
</script>

<style scoped lang="scss">
.bannerStyle {
  background: #2a50b7;
}

.bannerItem {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  height: 300px;
}

.number {
  font-weight: 600;
  font-size: 45px;
  color: #DDDDDD;
  line-height: 55px;
}

.keyword {
  font-weight: 600;
  font-size: 14px;
  color: #306FC7;
  line-height: 20px;
}

.desc {
  font-weight: 600;
  font-size: 14px;
  color: #666666;
  line-height: 20px;
}
</style>
