<template>
  <div class="app-container">
    <div style="padding: 0 12%">
      <div style="margin: 20px;"></div>
      <el-form :model="queryEntParams" ref="queryEntForm" size="small" :inline="true" label-width="68px">
        <el-form-item label="搜索公司">
        </el-form-item>
        <el-form-item prop="companyCountry">
          <el-select v-model="queryEntParams.companyCountry" filterable clearable placeholder="全部"
                     style="width: 170px">
            <el-option
                v-for="(item,index) in countryType"
                :key="index"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="companyName">
          <el-input
              v-model="queryEntParams.companyName"
              placeholder="请输入企业名称"
              clearable
              style="width: 240px"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="getCompanyList">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-card shadow="never">
        <div slot="header" class="clearfix">
          <breadcrumb style="margin-bottom: 20px;"></breadcrumb>
          <span class="el-card-title" style="margin-left: 10px;">企业查询结果</span>
          <span class="el-card-describe">Companies Search Result</span>
        </div>
        <div class="type" v-if="companyList.length > 0">
          <div v-for="(item,index) in companyList" :key="index" class="member-item">
            <img src="../assets/image/member.png" class="corner-image" alt="Corner">
            <div>
              <div class="name">{{ item.companyName }}</div>
              <div class="subTitle">注册号：<span class="desc">{{ item.businessLicenseCode }}</span></div>
              <div class="subTitle">地址：<span class="desc">{{ item.companyAddress }}</span></div>
            </div>
            <div class="loginBntBox" @click="lookDetail(item)">
              查看详情
            </div>
          </div>
        </div>
        <div v-else><el-empty description="没有找到对应查询结果"></el-empty></div>
        <pagination
            v-show="total>0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="getCompanyList"
        />
      </el-card>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/public/pagination.vue";
import Breadcrumb from "@/components/public/breadcrumb.vue";
import axios from '@/plugins/request';
import config from "@/config";

export default {
  name: "result.vue",
  components: {Breadcrumb, Pagination},
  data() {
    return {
      queryEntParams: {
        companyCountry: null,
        companyName: ''
      },
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 50
      },
      companyList: [],
      countryType: [
        {label: '全部', value: ''},
        {label: '中国', value: 1},
        {label: '马来西亚', value: 2}
      ],
    }
  },
  created() {
    let breadcrumb = [
      {name: '首页', path: '/homePage'},
      {name: '企业查询结果', path: '/companyResult'},
    ];
    this.$store.commit("setBreadcrumb", breadcrumb);
    let queryEntParams = window.localStorage.getItem("queryEntParams");
    if (queryEntParams) {
      this.queryCompany(queryEntParams);
    }
  },
  methods: {
    queryCompany(value) {
      this.queryEntParams = JSON.parse(value);
      this.getCompanyList();
    },
    getCompanyList() {
      let data = new FormData();
      data.append("companyCountry", this.queryEntParams.companyCountry)
      data.append("companyName", this.queryEntParams.companyName)
      data.append("pageNum", this.queryParams.pageNum)
      data.append("pageSize", this.queryParams.pageSize)
      axios.post(config.redirectUrl + "/api/company/membership/queryAllCompanies", data).then(res => {
        if (res.data.data.code === 0) {
          this.total = res.data.data.total
          this.companyList = res.data.data.rows;
          this.companyList.forEach(e => {
            if (e.companyCountry === '1') {
              e.companyAddress = e.companyAddress.length >= 20 ? e.companyAddress.substring(0, 20) + '...' : e.companyAddress;
            } else if (e.companyCountry === '2') {
              e.companyAddress = e.companyAddress.length >= 50 ? e.companyAddress.substring(0, 50) + '...' : e.companyAddress;
            }
          })
        }
      })
    },
    lookDetail(companyInfo) {
      window.localStorage.setItem("companyId", JSON.stringify(companyInfo.id));
      window.open(window.location.origin + "/companyDetails")
    }
  }
}
</script>

<style scoped lang="scss">
.bannerStyle {
  background: #2a50b7;
}

.cardList {
  display: flex;
  flex-wrap: wrap;

  .goodsName {
    font-size: 14px;
    font-weight: 600;
    color: #306FC7;
    line-height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 165px;
    display: inline-block;
    margin-top: 10px;
    cursor: pointer;
  }

  .address {
    font-size: 12px;
    font-weight: 400;
    color: #F5AF5D;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.cardList > div {
  flex: 0 0 20%; // 设置每个子元素占用20%的宽度
  max-width: 20%; // 设置最大宽度为20%，防止占用超过分配的空间
  display: flex;
  flex-direction: column; // 子元素内部使用列布局
  align-items: center; // 子元素内部居中对齐
  margin-bottom: 10px; // 根据需要调整，给下方元素留出空间
}

.type {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px; /* 新增的对应调整，为了使边缘对齐 */
}

.member-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 calc(50% - 40px);
  border: solid 1px #DDDDDD;
  border-radius: 6px;
  padding: 40px;
  margin: 20px;
}

.name {
  font-size: 20px;
  color: #306FC7;
  line-height: 35px;
}

.subTitle {
  color: #666666;
  font-size: 13px;
  line-height: 20px;
}

.desc {
  font-size: 13px;
  color: #666666;
  line-height: 20px;
}

.corner-image {
  position: absolute;
  top: 5px;
  right: 5px;
}

.loginBntBox {
  width: 95px;
  height: 45px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #306FC7;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 45px;
  color: #306FC7;
  cursor: pointer;
}

::v-deep .pagination-container .el-pagination {
  right: 15% !important;
  position: absolute;
}
</style>
