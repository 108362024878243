// src/plugins/axios.js
import axios from 'axios';
import store from '../store/index';
import JSONbig from 'json-bigint';

// 创建axios实例
const instance = axios.create({
    timeout: 1000 * 30,
    withCredentials: true,
    headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
        // 'Content-Type': 'application/json; charset=utf-8',
    },
    transformResponse: [
        function (data) {
            if (typeof data === 'string') {
                const JSONbigString = new JSONbig({storeAsString: true});
                return JSONbigString.parse(data);
            } else {
                return data;
            }
        }
    ]
});

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    const token = store.getters.token;
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

export default instance;
