<template>
  <div>
    <div class="bannerStyle">
      <el-carousel height="400px">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <img :src="item.img" class="bannerItem"/>
          <div class="bannerItem-box">
            <div class="bannerItem-box-title">{{ item.title }}</div>
            <div class="bannerItem-box-content">{{ item.content }}</div>
            <div class="tips">
              <div v-for="(tip, index) in item.tips" :key="index" style="display: flex">
                <div class="borderStyle"></div>
                <div style="padding: 0 20px;">
                  <div class="tipsTitle">{{ tip.animatedTitle }}
                    <span v-if="index === 0">亿美元+</span>
                    <span v-if="index === 1">家</span>
                    <span v-if="index === 2">个潜力领域</span>
                  </div>
                  <div class="tipsContent">{{ tip.content }}</div>
                </div>
              </div>
              <div class="borderStyle"></div>
            </div>
          </div>
          <div class="bannerItem-card">
            <div v-for="(card,index) in item.card" :key="index">
              <div :class="getClassForCard(card.position)" v-if="card.show">
                <el-card shadow="never">
                  <div class="card">
                    <img :src="card.image" style="width: 54px;height: 54px;">
                    <div style="margin-left: 20px;">
                      <div style="display: flex;align-items: center">
                        <div class="cardTitle">{{ card.name }}</div>
                        <img :src="card.country" style="width: 21px;height: 14px;margin-left: 5px;">
                      </div>
                      <div class="cardContent">{{ card.content }}</div>
                    </div>
                  </div>
                </el-card>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div style="padding: 0 12%">
      <query></query>
      <el-row :gutter="20">
        <el-col :span="4">
          <div style="display: flex;flex-direction: column">
            <productTypes></productTypes>
            <memberService></memberService>
          </div>
        </el-col>
        <el-col :span="20">
          <div style="display: flex;flex-direction: column">
            <el-card style="height: 340px;" shadow="never">
              <div slot="header" class="clearfix">
                <div>
                  <img src="../assets/image/china.png">
                  <span class="el-card-title" style="margin-left: 10px;">中国精选产品</span>
                  <span class="el-card-describe">Selected Chinese Exports</span>
                  <span class="el-card-handle" @click="whatHandle">如何申请入驻？</span>
                  <span class="handleHome" style="float: right;" @click="lookMore(0)">查看更多 ></span>
                </div>
              </div>
              <div class="cardList">
                <div v-for="(item,index) in chinaShopList" :key="index" style="flex: 0 0 20%" @click="lookDetail(item)">
                  <div style="text-align: center;"><img :src="item.goodsLogo"
                                                        style="height: 165px;width: 165px;border-radius: 6px;cursor: pointer;">
                  </div>
                  <div style="width: 165px;margin: 0 auto;">
                    <div class="productName" :title="item.goodsName">{{ item.goodsName }}</div>
                    <div class="address">
                      <img src="../assets/image/address.png" style="height: 20px;width: 20px;">
                      <span style="color: #F5AF5D;font-weight: 400;">{{ item.originPlace }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </el-card>
            <el-card style="height: 340px;margin-top: 20px;" shadow="never">
              <div slot="header" class="clearfix">
                <div>
                  <img src="../assets/image/malai.png">
                  <span class="el-card-title" style="margin-left: 10px;">马来西亚精选产品</span>
                  <span class="el-card-describe">Selected Malaysian Exports</span>
                  <span class="el-card-handle" @click="whatHandle">如何申请入驻？</span>
                  <span class="handleHome" style="float: right;" @click="lookMore(1)">查看更多 ></span>
                </div>
              </div>
              <div class="cardList">
                <div v-for="(item,index) in malaiShopList" :key="index" style="flex: 0 0 20%" @click="lookDetail(item)">
                  <div style="text-align: center;"><img :src="item.goodsLogo"
                                                        style="height: 165px;width: 165px;border-radius: 6px;cursor: pointer;">
                  </div>
                  <div style="width: 165px;margin: 0 auto;">
                    <div class="productName" :title="item.goodsName">{{ item.goodsName }}</div>
                    <div class="address">
                      <img src="../assets/image/address.png" style="height: 20px;width: 20px;">
                      <span style="color: #F5AF5D;font-weight: 400;">{{ item.originPlace }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
      <div style="margin:20px;"></div>
      <el-carousel height="150px">
        <el-carousel-item>
          <img src="../assets/image/centerBanner.png" style="width: 100%; height: auto;cursor: pointer;"
               @click="goRegister">
        </el-carousel-item>
      </el-carousel>
      <div style="margin:20px;"></div>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-card shadow="never">
            <div slot="header" class="clearfix">
              <img src="../assets/image/china.png">
              <span class="el-card-title" style="margin-left: 10px;">中国优秀会员企业推介</span>
              <span class="el-card-describe">Excellent Chinese Enterprises</span>
            </div>
            <div v-for="(item,index) in chinaEntList" :key="index" style="cursor: pointer;"
                 :style="index!==0?'margin-top: 20px;':''"
                 @click="lookEntDetail(item)">
              <div style="border: solid 1px #DDDDDD;border-radius: 6px;padding: 20px 40px;height: 186px;">
                <div style="display: flex;align-items: flex-end;">
                  <div style="font-size: 22px;color: #306FC7;">{{ item.companyName }}</div>
                  <img src="../assets/image/address.png" style="margin-left: 10px;">
                  <div style="font-size: 12px;color: #F5AF5D;">{{ item.location }}</div>
                </div>
                <div style="display: flex;flex-wrap: wrap;margin-top: 5px;">
                  <div class="entFlag" v-if="item.certificationMark && item.certificationMark.includes('1')">
                    <img src="../assets/image/entFlag1.png">
                    <div class="directory">企业已通过营业执照审核</div>
                  </div>
                  <div class="entFlag" v-if="item.certificationMark && item.certificationMark.includes('2')">
                    <img src="../assets/image/entFlag2.png">
                    <div class="directory">海关已验证报关单真实性</div>
                  </div>
                  <div class="entFlag" v-if="item.certificationMark && item.certificationMark.includes('3')">
                    <img src="../assets/image/entFlag3.png">
                    <div class="directory">企业信用报告可在线查阅</div>
                  </div>
                  <div class="entFlag" v-if="item.certificationMark && item.certificationMark.includes('4')">
                    <img src="../assets/image/entFlag4.png">
                    <div class="directory">企业已完成国际HALAL认证</div>
                  </div>
                  <div class="entFlag" v-if="item.certificationMark && item.certificationMark.includes('5')">
                    <img src="../assets/image/entFlag5.png">
                    <div class="directory">已通过GCA国际信用认证</div>
                  </div>
                  <div class="entFlag" v-if="item.certificationMark && item.certificationMark.includes('6')">
                    <img src="../assets/image/entFlag6.png">
                    <div class="directory">成功通过RMD全球风险检查</div>
                  </div>
                </div>
              </div>
            </div>
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <div class="handle" @click="whatHandle">如何申请入驻？</div>
              <div class="handleHome" @click="lookMemberDirectory">查看更多 ></div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card shadow="never">
            <div slot="header" class="clearfix">
              <img src="../assets/image/malai.png">
              <span class="el-card-title" style="margin-left: 10px;">马来西亚优秀会员企业推介</span>
              <span class="el-card-describe">Excellent Malaysian Enterprises</span>
            </div>
            <div v-for="(item,index) in malaiEntList" :key="index" style="cursor: pointer;"
                 :style="index!==0?'margin-top: 20px;':''"
                 @click="lookEntDetail(item)">
              <div style="border: solid 1px #DDDDDD;border-radius: 6px;padding: 20px 40px;height: 186px;">
                <div style="display: flex;align-items: flex-end;margin-top: 5px;">
                  <div style="font-size: 22px;color: #306FC7;">{{ item.companyName }}</div>
                  <img src="../assets/image/address.png" style="margin-left: 10px;">
                  <div style="font-size: 12px;color: #F5AF5D;">{{ item.location }}</div>
                </div>
                <div style="display: flex;flex-wrap: wrap;">
                  <div class="entFlag" v-if="item.certificationMark && item.certificationMark.includes('1')">
                    <img src="../assets/image/entFlag1.png">
                    <div class="directory">企业已通过营业执照审核</div>
                  </div>
                  <div class="entFlag" v-if="item.certificationMark && item.certificationMark.includes('2')">
                    <img src="../assets/image/entFlag2.png">
                    <div class="directory">海关已验证报关单真实性</div>
                  </div>
                  <div class="entFlag" v-if="item.certificationMark && item.certificationMark.includes('3')">
                    <img src="../assets/image/entFlag3.png">
                    <div class="directory">企业信用报告可在线查阅</div>
                  </div>
                  <div class="entFlag" v-if="item.certificationMark && item.certificationMark.includes('4')">
                    <img src="../assets/image/entFlag4.png">
                    <div class="directory">企业已完成国际HALAL认证</div>
                  </div>
                  <div class="entFlag" v-if="item.certificationMark && item.certificationMark.includes('5')">
                    <img src="../assets/image/entFlag5.png">
                    <div class="directory">已通过GCA国际信用认证</div>
                  </div>
                  <div class="entFlag" v-if="item.certificationMark && item.certificationMark.includes('6')">
                    <img src="../assets/image/entFlag6.png">
                    <div class="directory">成功通过RMD全球风险检查</div>
                  </div>
                </div>
              </div>
            </div>
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <div class="handle" @click="whatHandle">如何申请入驻？</div>
              <div class="handleHome" @click="lookMemberDirectory">查看更多 ></div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <div style="margin:20px;"></div>
      <el-card shadow="never">
        <div slot="header" class="clearfix">
          <span class="el-card-title">平台会员服务</span>
          <span class="el-card-describe">Platform Membership Service</span>
        </div>
        <div style="display: flex;justify-content: space-around;margin: 40px 0">
          <div v-for="(item,index) in memberService" :key="index"
               style="display: flex;flex-direction: column;align-items: center;">
            <img :src="item.img">
            <div style="font-size: 14px;margin-top: 10px;">{{ item.title }}</div>
          </div>
        </div>
        <div class="serviceButton">
          <el-button type="primary" style="text-align: center;" @click="handleRegister">立即注册</el-button>
        </div>
      </el-card>
      <div style="margin:20px;"></div>
    </div>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%">
      <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
        <img src="../assets/image/register.png" style="width: 100px;height: 100px;">
        <div class="register">如何申请成为平台会员单位？</div>
        <div class="register">立即注册然后填写申请表即可申请。</div>
        <el-button type="primary" @click="handleRegister" style="margin-top: 10px;">立即注册</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import productTypes from "@/components/public/productTypes.vue";
import memberService from "@/components/public/memberServices.vue";
import query from "@/components/public/query.vue";
import anime from "animejs/lib/anime.es.js";
import axios from '@/plugins/request';
import config from "@/config";

export default {
  name: "homePage",
  components: {
    productTypes, memberService, query
  },
  data() {
    return {
      dialogVisible: false,
      chinaShopList: [],
      malaiShopList: [],
      chinaEntList: [],
      malaiEntList: [],
      memberService:
          [
            {img: require("../assets/image/service1.png"), title: '申请海关报关单增信'},
            {img: require("../assets/image/service2.png"), title: '注册马来西亚公司'},
            {img: require("../assets/image/service3.png"), title: '开设跨境支付结算账户'},
            {img: require("../assets/image/service4.png"), title: '申请跨境贸易融资'},
            {img: require("../assets/image/service5.png"), title: '申请国际清真认证'},
            {img: require("../assets/image/service6.png"), title: '购买企业信用调研报告'},
          ],
      bannerList: [{
        img: require("../assets/image/banner/banner01.png"),
        title: "中国 - 马来西亚 跨境贸易中心",
        content: "为您连接马来西亚及东盟十国的贸易伙伴，实现生意增长",
        tips: [
          {
            title: 1902,
            animatedTitle: 0,
            content: '2023年中马贸易额'
          },
          {
            title: 31035,
            animatedTitle: 0,
            content: '马来西亚进出口贸易企业'
          },
          {
            title: 22,
            animatedTitle: 0,
            content: '源源不断的生意机会'
          }
        ],
        card: [
          {
            image: require("../assets/image/cpeople.png"),
            country: require("../assets/image/china.png"),
            name: '青岛某饮品企业',
            content: '中马平台帮助我们申请了国际哈拉认证，保障我们的产品可以畅通无阻的向东盟十国出口。',
            position: 'leftTop',
            show: true,
          },
          {
            image: require("../assets/image/mpeople.png"),
            country: require("../assets/image/malai.png"),
            name: '马来西亚某科技企业',
            content: '我们常年采购中国供应的手持一体机设备，中马平台帮助我们获得了贸易融资。',
            position: 'rightBottom',
            show: true,
          },
          {
            image: require("../assets/image/mpeople2.png"),
            country: require("../assets/image/malai.png"),
            name: '马来西亚某五金企业',
            content: '我们从中国进口钢材，生产弹簧并供应到马来西亚的企业，中马平台帮我们找到了更多的供应商。',
            position: 'rightTop',
            show: false,
          },
          {
            image: require("../assets/image/cpeople2.png"),
            country: require("../assets/image/china.png"),
            name: '中国某有色金属企业',
            content: '在政策倡导与扶持下，我们使用了关务信息服务，通过数据发掘了新的业务增长点。',
            position: 'leftBottom',
            show: false,
          }
        ]
      },
      ],
      intervalId: null,
      allParams: {},
    }
  },
  created() {
    this.$store.commit("setActiveIndex", "/homePage");
    this.checkForQueryParams()
    this.animateNumbers();
    this.getShopList();
    this.getEntList();
  },
  mounted() {
    this.intervalId = setInterval(this.switchPosition, 10000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    checkForQueryParams() {
      // 使用 URLSearchParams 检查是否有参数
      const searchParams = new URLSearchParams(window.location.search);
      const hasQueryParams = Array.from(searchParams).length > 0;
      console.log(hasQueryParams)
      if (hasQueryParams) {
        searchParams.forEach((value, key) => {
          this.allParams[key] = value;
        });
        let configs = {
          headers: {
            'Authorization': 'Bearer ' + this.allParams.token,
          }
        }
        this.$axios.post(config.redirectUrl + "/api/company/membership/queryMyCompanyDetail", {}, configs).then(res => {
          if (res.data) {
            this.$store.commit("setToken", this.allParams.token);
            this.getEntList()
          }
        }).catch(() => {
          this.$router.push({path: '/login'})
        })
      }
    },
    getShopList() {
      this.$axios.post(config.redirectUrl + "/api/goods/queryFeaturedGoods").then(res => {
        if (res.data.code === 0) {
          this.chinaShopList = res.data.data[1];
          this.malaiShopList = res.data.data[2];
        }
      })
    },
    getEntList() {
      axios.post(config.redirectUrl + "/api/company/membership/queryExcellentCompanies").then(res => {
        if (res.data.code === 0) {
          this.chinaEntList = res.data.data[1].slice(0, 2);
          this.malaiEntList = res.data.data[2].slice(0, 2);
          this.malaiEntList.forEach(e => {
            e.companyName = e.companyName.length >= 20 ? e.companyName.substring(0, 20) + '...' : e.companyName;
          })
        }
      })
    },
    animateNumbers() {
      this.bannerList.forEach(banner => {
        banner.tips.forEach(tip => {
          anime({
            targets: tip,
            animatedTitle: [0, tip.title], // 数字从 0 跳动到目标值
            easing: 'easeInOutQuad',
            round: 1, // 保证数字是整数
            duration: 2000, // 持续时间 2 秒
            update: function (anim) {
              tip.animatedTitle = Math.round(anim.animations[0].currentValue).toLocaleString(); // 使用 toLocaleString() 添加千位分隔符
            }
          });
        });
      });
    },
    lookDetail(productInfo) {
      window.localStorage.setItem("goodsId", JSON.stringify(productInfo.goodsId));
      window.open(window.location.origin + "/productDetails")
    },
    lookEntDetail(companyInfo) {
      window.localStorage.setItem("companyId", JSON.stringify(companyInfo.companyId));
      window.open(window.location.origin + "/companyDetails")
    },
    whatHandle() {
      this.dialogVisible = true;
    },
    handleRegister() {
      this.dialogVisible = false;
      this.$router.push({path: '/register'})
    },
    lookMore(value) {
      if (value === 0) {
        this.$router.push({path: '/exportProducts'})
        this.$store.commit("setActiveIndex", "/exportProducts");
      } else if (value === 1) {
        this.$router.push({path: '/importProducts'})
        this.$store.commit("setActiveIndex", "/importProducts");
      }
    },
    lookMemberDirectory() {
      this.$router.push({path: '/memberDirectory'})
    },
    goRegister() {
      this.$router.push({path: '/register'})
    },
    switchPosition() {
      this.bannerList.forEach(banner => {
        banner.card.forEach(card => {
          if (card.show) {
            this.$nextTick(() => {
              card.show = false;
            })
          } else {
            this.$nextTick(() => {
              card.show = true;
            })
          }
        });
      });
    },
    getClassForCard(position) {
      return position;
    }
  }
}
</script>

<style lang="scss" scoped>
.bannerStyle {
  background: #2a50b7;
}

.bannerItem {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.bannerItem-box {
  position: absolute;
  left: 12%;
  bottom: 95px;
  color: #fff;
  font-weight: 400;
}

.bannerItem-box-title {
  font-size: 40px;
  line-height: 40px;
}

.bannerItem-box-content {
  font-size: 30px;
  line-height: 60px;
}

.tips {
  display: flex;
  margin-top: 20px;
}

.tipsTitle {
  font-size: 30px;
  font-weight: bold;
  color: #6ACCFF;
  text-align: center;
  width: 200px;
}

.tipsContent {
  font-size: 18px;
  text-align: center;
}

.borderStyle {
  height: 68px;
  border: 1px solid #CFD7EF;
}

.bannerItem-card {
  position: absolute;
  bottom: 95px;
  right: 12%;
}

.cardTitle {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
}

.cardContent {
  font-size: 12px;
  line-height: 20px;
}

.card {
  display: flex;
  align-items: center;
  width: 360px;
  height: 60px;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); /* 可以根据需要调整 */
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0; /* 从透明开始 */
  }
  100% {
    opacity: 1; /* 到完全不透明结束 */
  }
}

.leftTop {
  position: relative;
  top: -40px;
  right: 10%;
  animation: fadeIn 2s ease-in-out, float 3s ease-in-out infinite;
}

.rightTop {
  position: relative;
  top: -40px;
  right: 10%;
  animation: fadeIn 2s ease-in-out, float 3s ease-in-out infinite;
}

.leftBottom {
  position: relative;
  top: 10px;
  bottom: -52px;
  right: -20%;
  animation: fadeIn 2s ease-in-out, float 3s ease-in-out infinite;
}

.rightBottom {
  position: relative;
  top: 10px;
  bottom: -52px;
  right: -20%;
  animation: fadeIn 2s ease-in-out, float 3s ease-in-out infinite;
}

.el-menu-demo {
  display: flex;
  justify-content: center;
}

.cardList {
  display: flex;

  .productName {
    font-size: 14px;
    font-weight: 600;
    color: #306FC7;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 165px;
    display: inline-block;
    margin-top: 10px;
    cursor: pointer;
  }

  .address {
    font-size: 12px;
    font-weight: 200;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.serviceButton {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.entFlag {
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.handle {
  width: 100px;
  height: 25px;
  background: #f6f6f6;
  border-radius: 13px;
  font-weight: 400;
  font-size: 12px;
  color: #666666;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  cursor: pointer;
}

.handleHome {
  font-weight: 600;
  font-size: 14px;
  color: #306FC7;
  margin-top: 15px;
  cursor: pointer;
}

.register {
  font-weight: 400;
  font-size: 16px;
  color: #666666;
  line-height: 25px;
}

.directory {
  font-size: 14px;
  color: #666666;
  margin-left: 5px;
}
</style>
