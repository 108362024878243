<template>
  <div class="app-container">
    <div class="bannerStyle">
      <img src="../assets/image/banner/banner03.png" alt="" class="bannerItem"/>
      <div class="bannerItem-text">
        <div class="bannerItem-text-title">出口产品展厅</div>
        <div class="bannerItem-text-content">为你的生意拓展一块新的版图，在马来西亚开拓无限可能。</div>
      </div>
    </div>
    <div style="margin:20px;"></div>
    <div style="padding: 0 12%">
      <el-row :gutter="20">
        <el-col :span="4">
          <div style="display: flex;flex-direction: column">
            <productTypes></productTypes>
            <memberService></memberService>
          </div>
        </el-col>
        <el-col :span="20">
          <el-card shadow="never">
            <div slot="header" class="clearfix">
              <breadcrumb style="margin-bottom: 20px;"></breadcrumb>
              <span class="el-card-title">商品列表</span>
              <span class="el-card-describe">Products List</span>
            </div>
            <div class="cardList">
              <div v-for="(item,index) in exportShopList" :key="index" @click="lookDetail(item)">
                <img src="../assets/image/china.png" style="position: relative;top: 14px;right: 72px;">
                <img :src="item.goodsLogo" style="height: 165px;width: 165px;border-radius: 6px;cursor: pointer;">
                <div style="width: 165px;margin: 0 auto;">
                  <div class="goodsName" :title="item.goodsName">{{ item.goodsName }}</div>
                  <div class="address">
                    <img src="../assets/image/address.png" style="height: 20px;width: 20px;">
                    <span>{{ item.originPlace }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div style="display: unset;">
              <pagination
                  v-show="total>0"
                  :total="total"
                  :page.sync="queryParams.pageNum"
                  :limit.sync="queryParams.pageSize"
                  @pagination="getExportShopList"
              />
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div style="margin:20px;"></div>
  </div>
</template>
<script>
import productTypes from "@/components/public/productTypes.vue";
import memberService from "@/components/public/memberServices.vue";
import breadcrumb from "@/components/public/breadcrumb.vue";
import FormData from "form-data";
import Pagination from "@/components/public/pagination.vue";
import config from "@/config";

export default {
  name: "exportShop",
  components: {
    Pagination,
    breadcrumb,
    productTypes, memberService
  },
  data() {
    return {
      productTypes: null,
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 50
      },
      exportShopList: [],
    }
  },
  created() {
    let breadcrumb = [
      {name: '首页', path: '/homePage'},
      {name: '出口产品', path: '/exportProducts'},
    ];
    this.$store.commit("setBreadcrumb", breadcrumb);
    this.getExportShopList();
  },
  methods: {
    getExportShopList() {
      let data = new FormData();
      data.append('importExportFlag', '0');
      data.append('pageNum', this.queryParams.pageNum);
      data.append('pageSize', this.queryParams.pageSize);
      this.$axios.post(config.redirectUrl + "/api/goods/queryGoodsList", data).then(res => {
        if (res.data.data.code === 0) {
          this.exportShopList = res.data.data.rows
          this.total = res.data.data.total
        }
      })
    },
    lookDetail(productInfo) {
      window.localStorage.setItem("goodsId", JSON.stringify(productInfo.goodsId));
      window.open(window.location.origin + "/productDetails")
    },
  }
}
</script>

<style scoped lang="scss">
.bannerStyle {
  background: #2a50b7;
}

.bannerItem {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  height: 300px;
}

.cardList {
  display: flex;
  flex-wrap: wrap;

  .goodsName {
    font-size: 14px;
    font-weight: 600;
    color: #306FC7;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 165px;
    display: inline-block;
    margin-top: 10px;
    cursor: pointer;
  }

  .address {
    font-size: 12px;
    font-weight: 400;
    color: #F5AF5D;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.cardList > div {
  flex: 0 0 20%; // 设置每个子元素占用20%的宽度
  max-width: 20%; // 设置最大宽度为20%，防止占用超过分配的空间
  display: flex;
  flex-direction: column; // 子元素内部使用列布局
  align-items: center; // 子元素内部居中对齐
  margin-bottom: 10px; // 根据需要调整，给下方元素留出空间
}
</style>
